import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getApplicationsList } from '../../../redux/actions/applications';
import Item from './Item/Item';
import Pagination from '../../../components/Pagination/Pagination';
import Form from '../../DirectoriesPage/Form/Form';
import Footer from '../../../components/Footer/Footer';
import { PAGE_SIZE_APPLICATIONS } from '../../../constants/const';
import NewLoader from '../../../components/NewLoader';

const PublicApplicationsPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [ready, setReady] = useState(false);
  const { auth } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { applications } = useSelector((state) => state);
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [formActive, setFormActive] = useState(false);
  const [filter, setFilter] = useState(false);
  const [filterForm, setFilterForm] = useState([]);
  const [currentSort, setCurrentSort] = useState('desc');
  const [selected, setSelected] = useState('datePublished');
  const [prevSelected, setPrevSelected] = useState('datePublished');


  const filterSave = () => {
    setFormActive(false);
    setFilter(false);
    sessionStorage.setItem('all', JSON.stringify(filterForm));
    setCurrentPage(1);
    getList();
  };

  const onSortChange = (event) => {
    let data = [];
    let sort = 'asc';
    let field = event.target.id;
    setSelected(field);

    if (field !== prevSelected) {
      setPrevSelected(field);
      setCurrentSort('asc');
    } else if (selected === prevSelected) {
      if (currentSort === 'asc') {
        setCurrentSort('desc');
        sort = 'desc';
      } else if (currentSort === 'desc') {
        setCurrentSort('asc');
        sort = 'asc';
      }
    }
    data.push({ sortName: field, sortValue: sort });
    setCurrentPage(1);
    getList(data);
  };

  const filterClick = () => {
    setFilter(true);
    setFormActive(true);
  };
  const searchHandler = (e) => {
    // setReady(false)

    if (e.target.value.length > 2) {
      // setReady(false);
      setCurrentPage(1);
      setSearch(e.target.value);
      let data = { name: 'all', value: e.target.value };
      getList(data);
    } else {
      setSearch(e.target.value);
      // getList()
    }
  };

  const getList = useCallback(
    async (arr, page) => {
      // setReady(false);
      let data = [
        { name: 'status', value: '1' },
        { name: 'status', value: '2' },
        { name: 'status', value: '6' },
        { name: 'status', value: '5' },
        { name: 'status', value: '8' },
        { name: 'status', value: '9' },
        { name: 'status', value: '10' },
        { name: 'status', value: '11' },
        { name: 'status', value: '12' },
        { name: 'status', value: '13' },
        { name: 'status', value: '15' },
        { name: 'status', value: '14' },
        { name: 'status', value: '16'},
        { name: 'status', value: '17' },
        { name: 'status', value: '18' },
        { name: 'status', value: '19' },
        { name: 'status', value: '20' },
        { name: 'status', value: '21' },
      ];
      if (sessionStorage.all) {
        let all = JSON.parse(sessionStorage.all);
        setFilterForm(all);
        if (all.length > 0) {
          all.forEach((element) => {
            if (element && element.value.length > 0) {
              data.push(element);
            }
          });
        }
      }
      if (search.length > 2 && (!arr || arr[0])) {
        data.push({ name: 'all', value: search });
      }
      if (
        arr &&
        arr[0] &&
        arr[0].sortName &&
        arr[0].sortName.length > 0 &&
        arr[0].sortName === selected
      ) {
        data.push({ sortName: selected, sortValue: arr[0].sortValue });
      } else if (
        arr &&
        arr[0] &&
        arr[0].sortName &&
        arr[0].sortName.length > 0 &&
        arr[0].sortName !== selected
      ) {
        data.push({ sortName: arr[0].sortName, sortValue: arr[0].sortValue });
      } else if (arr && arr.name && arr.name.length > 0) {
        data.push({ sortName: selected, sortValue: currentSort });
        data.push(arr);
      } else {
        data.push({ sortName: selected, sortValue: currentSort });
      }
      
      debugger;
      dispatch(getApplicationsList(data, page)).then((res) => {
        setReady(true);
      });
    },
    [currentPage, search, selected, currentSort],
  );

  useEffect(() => {
    if ((!ready && !auth.isLoggedIn) || search.length === 0) {
      getList();
    }
  }, [search]);

  return (
    <div className="main public-page">
      <div
        className="public-sub-header"
        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <h3>Публічний реєстр присвоєння спортивних звань</h3>
        <button
          className={
            filterForm && filterForm.length > 0
              ? 'icon-text-btn-secondary filter filter-on'
              : 'icon-text-btn-secondary filter'
          }
          style={{ marginTop: '30px' }}
          onClick={filterClick}
        >
          Фільтр
        </button>
      </div>

      <h1 className="public-header">
        Заявки для присвоєння спортивних звань {!!applications.total && `(${applications.total})`}
      </h1>
      <label className="search" style={{ marginBottom: '30px' }}>
        <div className="btn">
          <input
            className="search-input"
            type="text"
            onChange={searchHandler}
            value={search}
            placeholder="Пошук (мінімум 3 символа)"
          />
          <i className="icon search-icon"></i>
        </div>
      </label>
      <div className="public-table" style={{ overflowX: 'auto' }}>
        <table className="public-table-cust">
          <thead>
            <tr>
              <th
                onClick={onSortChange}
                className={selected === 'datePublished' ? `selected ${currentSort}` : ''}
                id="datePublished"
                width="75"
              >
                <span id="datePublished">Дата</span>
              </th>
              <th
                onClick={onSortChange}
                className={selected === 'athleteName' ? `selected ${currentSort}` : ''}
                id="athleteName"
                style={{ minWidth: '280px' }}
              >
                {' '}
                <span id="athleteName" className="two-rows">
                  ПІБ спортсмена/тренера
                  <br />
                  Поточний етап заявки, дні в обробці
                </span>
              </th>
              <th
                id="sex"
              >
                <span id="sex">Стать</span>
              </th>
              <th
                onClick={onSortChange}
                className={selected === 'athleteCategoryTitle' ? `selected ${currentSort}` : ''}
                id="athleteCategoryTitle"
              >
                <span id="athleteCategoryTitle">Звання</span>
              </th>
              <th
                onClick={onSortChange}
                className={selected === 'sportTitle' ? `selected ${currentSort}` : ''}
                id="sportTitle"
              >
                <span id="sportTitle">Вид спорту</span>
              </th>
              <th
                onClick={onSortChange}
                className={selected === 'organisationName' ? `selected ${currentSort}` : ''}
                id="organisationName"
              >
                <span id="organisationName">Суб’єкт подання</span>
              </th>
              <th
                onClick={onSortChange}
                className={selected === 'company' ? `selected ${currentSort}` : ''}
                id="company"
              >
                <span id="company">Відомство</span>
              </th>
              <th
                onClick={onSortChange}
                className={selected === 'organisationRegion' ? `selected ${currentSort}` : ''}
                id="organisationRegion"
              >
                <span id="organisationRegion">Область, місто</span>
              </th>
              <th id="tournamentConfirmationDocumentUrl"></th>
            </tr>
          </thead>
          <tbody>
            {ready &&
              applications &&
              applications.list &&
              applications.list.length > 0 ?
              applications.list.map((item) => {
                return <Item item={item} key={item.id} history={history} />;
              }):
              <tr >
                <td colSpan="9">

                {ready && applications?.list?.length==0?<h2>Жодної заявки</h2>
                : <NewLoader/>} 
                </td>
              </tr>
              }
          </tbody>
          {applications && applications.total > PAGE_SIZE_APPLICATIONS && (
            <tfoot className="">
              <tr>
                <td colSpan="9">
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={applications.total}
                    pageSize={PAGE_SIZE_APPLICATIONS}
                    onPageChange={async (page) => {
                      setReady(false);
                      await setCurrentPage(page);
                      await getList(null, page);
                    }}
                  />
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      </div>
      <Footer />
      {formActive && filter && (
        <Form
          form={filterForm}
          saveAddChange={filterSave}
          filterApp={true}
          filter={filter}
          setForm={setFilterForm}
          setFormActive={setFormActive}
        />
      )}
    </div>
  );
};

export default PublicApplicationsPage;
