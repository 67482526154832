import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentOrganisation } from '../../../../redux/selectors/organisations';
import { AppStatus, OrgType } from '../../../../constants/const';
import { selectApplicationStatusTitles } from '../../../../redux/selectors/dictionaries';

const Item = (props) => {
  const [reg, setReg] = useState({});
  const [status, setStatus] = useState({});
  const [block, setBlock] = useState(false);
  const [checked, setChecked] = useState(false);
  const { fullList, list } = useSelector((state) => state);
  const appStatuses = useSelector(selectApplicationStatusTitles);
  const currentOrganisation = useSelector(selectCurrentOrganisation);
  const getItem = useCallback(async () => {
    if (fullList) {
      fullList.forEach((el) => {
        if (el) {
          el.forEach((element) => {
            if(props.item.regionId &&  element.id === props.item.regionId){
              setReg(element);
            }
            else if (!props.item.regionId && props.item.organisation && element.id === props.item.organisation.regionId) {
              setReg(element);
            }
          });
        }
      });
    }
  }, [fullList, props.item.organisation]);

  const getActualStatusList =(category)=>{
    let arr = [];
    let prevArr = [];
    if(category<2){
      // arr[0]=fullList[4][0];
      // arr[1]=fullList[4][1];
      arr[2]=fullList[4][2];
      arr[3]=fullList[4][6];
      arr[4]=fullList[4][5];
      arr[5]=fullList[4][8];
      arr[6]=fullList[4][9];
      arr[7]=fullList[4][15];
      arr[8]=fullList[4][14];
      arr[9]=fullList[4][17];
      arr[10]=fullList[4][18];
      arr[11]=fullList[4][19];
      arr[12]=fullList[4][20];
      arr[13]=fullList[4][21];
      arr[14]=fullList[4][22];
    }else{
      // arr[0]=fullList[4][0];
      // arr[1]=fullList[4][1];
      arr[2]=fullList[4][2];
      arr[3]=fullList[4][11];
      arr[4]=fullList[4][10];
      arr[5]=fullList[4][13];
      arr[6]=fullList[4][15];
      arr[7]=fullList[4][14];
      arr[8]=fullList[4][17];
      arr[9]=fullList[4][18];
      arr[10]=fullList[4][19];
      arr[11]=fullList[4][20];
      arr[12]=fullList[4][21];
      arr[13]=fullList[4][22];
    }
    return arr;
  }

  const getChecked = (event) => {
    if (props.selectId && props.selectId.length > 0) {
      props.selectId.map((el) => {
        if (el === props.item.id) {
          setChecked(true);
        }
      });
    }
    if (event && event.target.checked === false) {
      setChecked(false);
    } else if (event && event.target.checked === true) {
      setChecked(true);
    }
  };

  const getStatus = useCallback(async () => {
    if (fullList && fullList[4]) {
      fullList[4].forEach((el) => {
        if (el.status === props.item.status) {
          setStatus(el);
        }
      });
    }
  }, [fullList]);

  const openList = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (block) {
      setBlock(false);
    } else {
      setBlock(true);
    }
  };

  useEffect(() => {
    getChecked();
    getItem(fullList);
    getStatus(fullList);
  }, [fullList]);

  const clickHandler = (event) => {
    if (event.target.nodeName !== 'INPUT') {
      props.history.push(`/applications/${event.currentTarget.id}`);
    }
  };

  let currDate = new Date();
  let prevDate = new Date(props.item.updatedAt);
  let diff = new Date(currDate.getTime() - prevDate.getTime());

  return (
    <tr id={props.item.id} className={props.item.status===1 || (props.item.status===5 && !props.item.isTrainerReview)?'bgAction':''} onClick={clickHandler} style={{position:'relative'}}>
      <td>
        {props.type === 'work' &&
        status &&
        status.status &&
        [AppStatus.Accepted, AppStatus.RewardSportReview].includes(status.status) &&
        currentOrganisation &&
        currentOrganisation.type &&
        currentOrganisation.type === OrgType.RewardSport &&
        (!props.selectTitle ||
          (props.selectTitle && props.item.athleteCategoryTitle === props.selectTitle)) ? (
          <input
            name={props.item.id}
            slot={props.item.athleteCategoryTitle}
            onChange={(event) => {
              props.selectHandler(event);
              getChecked(event);
            }}
            checked={checked}
            type="checkbox"
          />
        ) : (
          ''
        )}
        {props.type === 'done' &&
        status &&
        status.status &&
        [AppStatus.CategoryAssigned, AppStatus.CertificateReady].includes(status.status) &&
        currentOrganisation &&
        currentOrganisation.type &&
        currentOrganisation.type == OrgType.RewardSport &&
        (!props.selectTitle ||
          (props.selectTitle && props.item.athleteCategoryTitle === props.selectTitle)) ? (
          <input
            name={props.item.id}
            slot={props.item.sportCategoryType}
            onClick={(event) => {
              props.selectHandler(event);
              getChecked(event);
            }}
            checked={checked}
            type="checkbox"
          />
        ) : (
          ''
        )}
      </td>
      <td>
        {props.item.datePublished
          ? new Date(props.item.datePublished).toLocaleString('uk-UA', {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
            })
          : ''}
      </td>
      <td>
        {props.item.athleteCategoryTitle
          ? props.item.athleteCategoryTitle.split(' ').map((el) => {
              return el[0].toUpperCase();
            })
          : '---'}
      </td>
      <td>{props.item.sportTitle ? props.item.sportTitle : '---'}</td>
      <td>
        {props.item.athleteLastName
          ? props.item.athleteLastName +
            ' ' +
            props.item.athleteFirstName +
            ' ' +
            props.item.athleteSecondName
          : '---'}{' '}
        <br />
        <span className="application-span" onClick={openList}>
          {props.item.status === 15 ? (
            <span style={{ color: 'red' }}>{appStatuses[props.item.status]}</span>
          ) : (
            appStatuses[props.item.status]
          )}{' '}
          {diff.getUTCFullYear() - 1970 > 0 && <>{diff.getUTCFullYear() - 1970}р</>}
          {diff.getUTCMonth() > 0 && <>{diff.getUTCMonth()} м.</>}
          {diff.getUTCDate() - 1 > 0 && <>({diff.getUTCDate() - 1}д.)</>}
        </span>
        {block && (
          <div className="application-block" onMouseLeave={(e) => setBlock(false)}>
            {getActualStatusList(props.item.sportCategoryType).map((el,index)=>{
              const currentStatusIndex = getActualStatusList(props.item.sportCategoryType).findIndex(elem => elem?.status == props?.item?.status);            
            if (index === currentStatusIndex) {
                return (
                  <span style={{ fontWeight: 'bold' }} className="application-block-item">
                    {' '}
                    {el.title}
                    <br />{' '}
                  </span>
                );
            }else if(index> currentStatusIndex){
              return (
                <span className="application-block-item">
                {' '}
                {el.title}
                <br />{' '}
              </span>)
            }else if (index<currentStatusIndex){
              return (
              <span style={{ color: '#C2C5CB' }} className="application-block-item">
              {' '}
              {el.title} 
             
              <br />{' '}
              {props.item?.applicationsSignatory?.map(element=>{
                if(element.status==el.status && element.lastName.length>0){
                  return(
                    <span style={{ color: 'black' }}> {element.lastName  + ' ' + element.firstName} </span> 
                    )
                }
              })}
              </span>)
            }
          })};
          </div>
        )}
      </td>
      <td>
        {props.item.sex?list[props.item.sex]:'---'}
      </td>
      <td>
        {props.item.sportCategoryType > 1 && <>Укр центр "Інваспорт"</>}
        {props.item.sportCategoryType < 2 && props.item.sportCategoryTitle}
        {!props.item.sportCategoryType && props.item.sportCategoryType != 0 && '---'}
      </td>
      <td>{props.item?.organisation?.shortName || '---'} </td>
      <td>
        {list[props.item.company] || props.item.company ||'---'}
      </td>
      <td>{reg.title || '---'}</td>
    </tr>
  );
};

export default Item;
