const SIGN_WIDGET_PARENT_ID = 'sign-widget-parent';
const SIGN_WIDGET_ID = 'sign-widget';
const SIGN_WIDGET_URI = 'https://id.gov.ua/sign-widget/v20220527/';
// const SIGN_WIDGET_URI = 'https://test.id.gov.ua/sign-widget/v2022testnew/'


let euSign;

const init = (onConfirmKSPOperation) => {
  if (window.EndUser) {
    euSign = new window.EndUser(
      SIGN_WIDGET_PARENT_ID,
      SIGN_WIDGET_ID,
      SIGN_WIDGET_URI,
      window.EndUser.FormType.ReadPKey,
      new window.EndUser.FormParams(),
    );
    if (onConfirmKSPOperation) {
      euSign.AddEventListener(window.EndUser.EventType.ConfirmKSPOperation, onConfirmKSPOperation);
    }
  }
  window.euSign = euSign;
};

const readKey = async () => {
  try {
    const readData = await euSign.ReadPrivateKey();
    return readData[0].infoEx;
  } catch (error) {
    console.log(error);
  }
};

const sign = async (data, previousSign = null) => {
  try {
    return await euSign.SignData(
      base64ToUint8Array(data),
      true,
      true,
      window.EndUser.SignAlgo.DSTU4145WithGOST34311,
      previousSign ? base64ToUint8Array(previousSign) : null,
      window.EndUser.SignType.CAdES_X_Long_Trusted,
    );
  } catch (error) {
    console.log(error);
  }

  function base64ToUint8Array(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes;
  }
};

export default {
  init,
  readKey,
  sign,
};
