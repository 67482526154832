import React from 'react';

class Order extends React.Component {
  constructor(props) {
    super(props);
    this.orderRef = React.createRef();
  }

  getInnerHTML = () => {
    return this.orderRef.current ? this.orderRef.current.innerHTML : '';
  };

  render() {
    const sidebar = document.getElementsByClassName('sidebar')[0];
    if (sidebar && sidebar.hidden !== true) {
      sidebar.hidden = true;
    }

    const arr = this.props.certificate.list;
    const newArr = [];

    arr.forEach((el) => {
      if (!newArr.includes(el.application.sportCategoryTitle)) {
        newArr.push(el.application.sportCategoryTitle);
      }
    });

    const renderedCategories = new Set();

    return (
      <div
        ref={this.orderRef} // Attach ref here
        style={{
          fontSize: '18px',
          fontFamily: '"Times New Roman"',
          orientation: 'landscape',
          gridArea: 'article',
          width: '794px',
        }}
      >
        <div
          style={{
            padding: '0.5cm 1cm 0 3cm',
            fontFamily: '"Times New Roman"',
            fontSize: '18px',
          }}
        >
          <img src="/Res/Gerb.png" style={{ margin: '0 45%' }} alt="" />
          <p
            style={{
              fontWeight: 'bold',
              fontSize: '22px',
              textAlign: 'center',
            }}
          >
            МІНІСТЕРСТВО МОЛОДІ ТА СПОРТУ УКРАЇНИ
          </p>
          <br style={{ fontSize: '14px' }} />
          <p
            style={{
              fontWeight: 'bold',
              fontSize: '24px',
              textAlign: 'center',
            }}
          >
            {' '}
            Н А К А З
          </p>
          <br style={{ fontSize: '14px' }} />
          <p style={{ textAlign: 'center', display: 'flex', justifyContent: 'space-between' }}>
            <p>від ____________ 20__р.</p> <p>Київ</p>{' '}
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;№ __________</p>
          </p>
          <br style={{ fontSize: '14px' }} />
          <br style={{ fontSize: '14px' }} />
          <br style={{ fontSize: '14px' }} />
          <p
            style={{
              textAlign: 'left',
              fontWeight: '900',
            }}
          >
            Про присвоєння спортивного звання
          </p>
          <p
            style={{
              textAlign: 'left',
              fontWeight: '900',
            }}
          >
            "
            {this.props.certificate &&
              this.props.certificate.list &&
              this.props.certificate.list[0] &&
              this.props.certificate.list[0].application.athleteCategoryTitle.split('/')[0]}
            "
          </p>
          <br style={{ fontSize: '14px' }} />
          <br style={{ fontSize: '14px' }} />
          <br style={{ fontSize: '14px' }} />
          <p style={{ fontSize: '18px', textAlign: 'justify', textIndent: '40px' }}>
            {' '}
            Відповідно до вимог Положення про Єдину спортивну класифікацію України, затвердженого
            наказом Мінмолодьспорту від 07.12.2023 № 7512, зареєстрованого в Міністерстві юстиції
            України 25 грудня 2023 р. за № 2240/41296 (зі змінами),
          </p>
          <br style={{ fontSize: '14px' }} />
          <p
            style={{
              fontSize: '18px',
              fontWeight: '900',
            }}
          >
            НАКАЗУЮ:
          </p>
          <br style={{ fontSize: '14px' }} />

          {newArr &&
            newArr.length > 0 &&
            newArr.map((el, index) => {
              if (renderedCategories.has(el)) {
                return null;
              }

              renderedCategories.add(el);

              let res = '';
              switch (el) {
                case 'Неолімпійський':
                  res = 'неолімпійських видів спорту';
                  break;
                case 'Олімпійський':
                  res = 'олімпійських видів спорту';
                  break;
                default:
                  res = 'видів спорту осіб з інвалідністю';
                  break;
              }

              return (
                <React.Fragment key={el}>
                  <p
                    style={{
                      fontSize: '18px',
                      textAlign: 'justify',
                      textIndent: '40px',
                    }}
                  >
                    {index + 1}. Присвоїти спортивне звання "
                    {this.props.certificate &&
                      this.props.certificate.list[0].application.athleteCategoryTitle.split('/')[0]}
                    " спортсменам з {res} згідно зі списком{' '}
                    {newArr.length === 1 ? ', що додається.' : `(додаток ${index + 1}).`}
                  </p>
                  <br style={{ fontSize: '14px' }} />
                </React.Fragment>
              );
            })}

          <p
            style={{
              fontSize: '18px',
              textAlign: 'justify',
              textIndent: '40px',
            }}
          >
            {newArr.length + 1}. Контроль за виконанням цього наказу залишаю за собою.
          </p>
          <br style={{ fontSize: '14px' }} />
          <br style={{ fontSize: '14px' }} />
          <br style={{ fontSize: '14px' }} />
          <p style={{ margin: '0 0 160px', fontSize: '18px' }}>
            {this.props.certificate &&
              this.props.certificate.list[0].signer1Position &&
              this.props.certificate.list[0].signer1Position}
            <span style={{ float: 'right', marginRight: '0.5cm' }}>
              {this.props.certificate &&
                this.props.certificate.list[0].signer1Name &&
                this.props.certificate.list[0].signer1Name}
            </span>
          </p>
        </div>
      </div>
    );
  }
}

export default Order;
