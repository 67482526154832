import React, { useCallback, useState, useEffect } from 'react';
import Item from '../Item/Item';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCertificateList,
  getCertificateUniqueList,
} from '../../../../redux/actions/certificate';
import Form from '../../../DirectoriesPage/Form/Form';
import Pagination from '../../../../components/Pagination/Pagination';
import { ExportCSV } from '../../../PrintPages/Word';
import { selectCertificates } from '../../../../redux/selectors/certificates';
import CertificateService from '../../../../redux/services/certificate.service';
import { PAGE_SIZE_CERTIFICATES } from '../../../../constants/const';
import ApplicationService from '../../../../redux/services/application.service';
import NewLoader from '../../../../components/NewLoader';

const Order = (props) => {
  const history = useHistory();
  const [select, setSelect] = useState('');
  const dispatch = useDispatch();
  const certificates = useSelector(selectCertificates);
  const { fullList } = useSelector((state) => state);
  const { uniqCertificate } = useSelector((state) => state);
  const [comment, setComment] = useState({});
  const [formArr, setFormArr] = useState([]);
  const [formActive, setFormActive] = useState(false);
  const [orderReg, setOrderReg] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [order, setOrderActive] = useState(false);
  const [orderForm, setOrderForm] = useState({});
  const [alertActive, setAlertActive] = useState(false);

  const getList = useCallback((orderNumber) => {
    dispatch(getCertificateList(orderNumber));
  }, []);

  const printOrderSave = (e) => {
    let arr = [];
    if (certificates.length > 0) {
      certificates.forEach((certificate) => {
        arr.push(certificate.application.id);
      });

      if (certificates[0].application.status === 19) {
        let data2 = {
          applications: arr,
          data: {},
        };
        fullList[8].forEach((el) => {
          if (el.id === orderForm.signer1Name) {
            data2.data.signer1Name = el.firstName + ' ' + el.lastName.toUpperCase();
            data2.data.signer1Position = el.position;
          }
        });
        CertificateService.updateMultipleCertificate(data2);
      } else {
        let data = {
          applications: arr,
          data: {
            status: 19,
          },
        };
        ApplicationService.updateMultipleApplications(data).then((res) => {
          let data2 = {
            applications: arr,
            data: {},
          };
          fullList[8].forEach((el) => {
            if (el.id === orderForm.signer1Name) {
              data2.data.signer1Name = el.firstName + ' ' + el.lastName.toUpperCase();
              data2.data.signer1Position = el.position;
            }
          });
          CertificateService.updateMultipleCertificate(data2);
        });
      }
      history.push(`/print/${certificates[0].orderNumber}/order`);

      // certificates.forEach(element => {
      //     let data={
      //         applicationId: element.application.id,
      //         id:element.application.id,
      //         status:19,
      //     };
      //     fullList[8].forEach(el=>{
      //         if(el.id ==orderForm.signer1Name){
      //             data.signer1Name =el.firstName+' '+el.lastName.toUpperCase();
      //             data.signer1Position = el.position
      //         }
      //     })
      //     console.log(element);
      //     console.log(orderForm);
      //     dispatch(createHistory(data)).then(res=>{
      //         dispatch(updateCertificate(data)).then(res=>{
      //             console.log(res);
      //         })
      //     })
      // })
    }
  };

  const printOrderClick = (e) => {
    e.stopPropagation();
    setOrderReg(false);
    setFormActive(true);
    setOrderActive(true);
  };

  const disbandOrderClick = (e) => {
    e.stopPropagation();
    setAlertActive(true);
  };

  const disbandOrder = () => {
    setAlertActive(false);
    CertificateService.disbandCertificate(select).then(getCertificateUniqList);
  };

  const registerOrderClick = (e) => {
    e.stopPropagation();
    setOrderActive(false);
    setOrderReg(true);
    setFormActive(true);
  };

  const registerOrder = () => {
    let arr = [];
    if (certificates.length > 0) {
      certificates.forEach((element) => {
        arr.push(element.application.id);
      });
      let data = {
        applications: arr,
        data: {
          status: 20,
        },
      };
      ApplicationService.updateMultipleApplications(data).then((res) => {
        let data2 = {
          applications: arr,
          data: {
            orderNumber: comment.orderNumber,
            assignDate: comment.assignDate,
            orderUrl:comment.orderUrl
          },
        };
        CertificateService.updateMultipleCertificate(data2).then(() => {
          setOrderReg(false);
          setFormActive(false);
          getCertificateUniqList();
        });
      });
    }
  };

  const downloadClick =(e)=>{
    let { id } = e.currentTarget;
    window.location.href = id;
  }

  const uploadExel = () => {
    let arr = [];
    if (certificates.length > 0 && certificates[0].application.status !== 19) {
      certificates.forEach((element) => {
        arr.push(element.application.id);
      });
      let data = {
        applications: arr,
        data: {
          status: 19,
        },
      };
      ApplicationService.updateMultipleApplications(data).then((res) => {
        getCertificateUniqList();
      });
    }
    setFormArr([]);
    return arr;
  };

  const getCertificateUniqList = useCallback(() => {
    dispatch(getCertificateUniqueList(currentPage));
  }, []);

  useEffect(() => {
    getCertificateUniqList();
  }, []);

  return (
    <>
      {(uniqCertificate &&
        uniqCertificate.list &&
        uniqCertificate.list.length > 0) ?
        uniqCertificate.list.map((orderNumber) => {
          return (
            <React.Fragment key={orderNumber}>
              <tr
                className={
                  select === orderNumber ? 'selected notification-item' : 'notification-item'
                }
                onClick={
                  select === orderNumber
                    ? () => setSelect('')
                    : () => {
                        setSelect(orderNumber);
                        getList(orderNumber);
                      }
                }
              >
                <td colSpan="3">Шаблон наказу створено {orderNumber}</td>
                <td colSpan="7" style={{ textAlign: 'right' }}>
                  {select === orderNumber && (
                    <>
                      <button className="icon-text-btn-secondary" onClick={printOrderClick}>
                        Друкувати
                      </button>
                      <button className="icon-text-btn-secondary" onClick={registerOrderClick}>
                        Зареєструвати
                      </button>
                      <button className="icon-text-btn-secondary" onClick={disbandOrderClick}>
                        Розформувати
                      </button>
                     {certificates.length>0 && certificates[0].urlZip?.length>0 &&  <button className="icon-text-btn-secondary" id={certificates[0].urlZip} onClick={downloadClick}>
                        Завантажити архів
                      </button>}
                      <ExportCSV uploadExel={uploadExel} csvData={formArr} fileName="Export" />
                    </>
                  )}
                </td>
              </tr>
              {(select === orderNumber &&
                certificates.length > 0 )&&
                certificates.map((certificate) => {
                  return (
                    <Item
                      item={certificate.application}
                      type={props.type}
                      selectHandler={props.selectHandler}
                      key={certificate.application.id}
                      history={history}
                    />
                  );
                })
              }
            </React.Fragment>
          );
        }):
        <tr >
          <td colSpan="9">
          {uniqCertificate?.list?.length==0?<h2>Жодного наказу</h2>
          : <NewLoader/>}
          </td>
        </tr>
        }
        

      {uniqCertificate && uniqCertificate.total > PAGE_SIZE_CERTIFICATES && (
        <tr>
          <td colSpan="7">
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={uniqCertificate.total}
              pageSize={PAGE_SIZE_CERTIFICATES}
              onPageChange={async (page) => {
                await setCurrentPage(page);
                await getCertificateUniqList();
              }}
            />
          </td>
        </tr>
      )}

      {formActive && orderReg && (
        <Form
          form={comment}
          saveAddChange={registerOrder}
          setForm={setComment}
          orderReg={orderReg}
          setFormActive={setFormActive}
        />
      )}
      {formActive && order && (
        <Form
          form={orderForm}
          saveAddChange={printOrderSave}
          order={order}
          setForm={setOrderForm}
          setFormActive={setFormActive}
        />
      )}
      {alertActive && (
        <div className="alert-label">
          <div>
            Ви бажаєте розформувати наказ? Ця дія поверне Заявки до попереднього етапу та видалить
            наказ.
          </div>
          <div className="alert-label-buttons">
            <button className="icon-text-btn-secondary" onClick={disbandOrder}>
              <i className="icon return-icon"></i>
              Розформувати
            </button>
            <button
              className="round-btn-secondary close"
              onClick={() => setAlertActive(false)}
            ></button>
          </div>
        </div>
      )}
    </>
  );
};

export default Order;
