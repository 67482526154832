import React from 'react';

class Res1 extends React.Component {
  render() {
    if (
      document.getElementsByClassName('sidebar') &&
      document.getElementsByClassName('sidebar')[0] &&
      document.getElementsByClassName('sidebar')[0].hidden !== true
    ) {
      document.getElementsByClassName('sidebar')[0].hidden = true;
    }

    return (
      <div style={{ marginLeft: 'auto', marginRight: 'auto', width: '107mm' }}>
        {this.props.certificate &&
          this.props.certificate.list.map((el, index) => {
            return (
              <React.Fragment key={el.application.id}>
                {(el.application.athleteCategoryTitleId ===
                  '391fcc05-5ef6-4f49-a432-e4f3dd6c1432'|| el.application.athleteCategoryTitle==='Майстер спорту України міжнародного класу/МСУМК') && (
                  <div
                    style={
                      (index + 1) % 3 === 0
                        ? { margin: '20px auto 0', height: '70mm', pageBreakAfter: 'always' }
                        : { margin: '20px auto 0', height: '70mm' }
                    }
                  >
                    <div
                      style={{
                        width: '107mm',
                        height: '70mm',
                        background: 'URL("/Res/graymarble.png")',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        lineHeight: '1.3',
                      }}
                    >
                      <div
                        style={{
                          width: ' 96mm',
                          height: ' 56mm',
                          border: '1mm solid #000077',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            width: ' 91mm',
                            height: '51mm',
                            border: '1mm solid yellow',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          <div
                            style={{
                              height: ' 100%',
                              width: ' 100%',
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <div
                              style={{
                                height: '100%',
                                width: '37%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <img
                                src="/Res/msumksign.png"
                                alt="значок"
                                style={{ width: ' 27mm', height: ' 35mm' }}
                              />
                            </div>
                            <div
                              style={{
                                height: ' 100%',
                                width: ' 63%',
                                textAlign: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: '#000077',
                                fontSize: '6mm',
                                fontFamily: '"Times New Roman"',
                              }}
                            >
                              <strong>
                                МАЙСТЕР
                                <br />
                                СПОРТУ
                                <br />
                                УКРАЇНИ
                              </strong>
                              МІЖНАРОДНОГО
                              <br />
                              КЛАСУ
                            </div>
                          </div>
                          <div style={{ background: '#0057b8', height: '4mm', width: '100%' }}>
                            &nbsp;
                          </div>
                          <div style={{ background: '#ffd700', height: '4mm', width: '100%' }}>
                            &nbsp;
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {(el.application.athleteCategoryTitleId ===
                  'f67f6f82-0d44-43b6-847c-fb1be013e3c9' || el.application.athleteCategoryTitle==='Майстер спорту України/МСУ')&&(
                  <div style={{ margin: '20px auto 0', height: '70mm' }}>
                    <div
                      style={{
                        width: ' 107mm',
                        height: '70mm',
                        background: 'URL("/Res/graymarble.png")',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        lineHeight: '1.3',
                      }}
                    >
                      <div
                        style={{
                          width: ' 91mm',
                          height: ' 51mm',
                          border: '1mm solid yellow',
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <div
                          style={{
                            height: ' 100%',
                            width: ' 100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <div
                            style={{
                              height: ' 100%',
                              width: ' 37%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <img
                              src={'/Res/msusign.png'}
                              alt="значок"
                              style={{ width: ' 27mm', height: '35mm' }}
                            />
                          </div>
                          <div
                            style={{
                              height: ' 100%',
                              width: ' 63%',
                              textAlign: 'center',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              color: '#000077',
                              fontSize: '6mm',
                            }}
                          >
                            <strong style={{ fontFamily: '"Times New Roman"' }}>
                              МАЙСТЕР
                              <br />
                              СПОРТУ
                              <br />
                              УКРАЇНИ
                            </strong>
                          </div>
                        </div>
                        <div style={{ background: ' #0057b8', height: '4mm', width: '100%' }}>
                          &nbsp;
                        </div>
                        <div style={{ background: '#ffd700', height: '4mm', width: '100%' }}>
                          &nbsp;
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            );
          })}
      </div>
    );
  }
}

export default Res1;
