import React from 'react';
import { Redirect } from 'react-router-dom';

class PodanNew2 extends React.Component {
  constructor(props) {
    super(props);
  }
  state = { redirect: '/applications' };
  render() {
    if (
      document.getElementsByClassName('sidebar') &&
      document.getElementsByClassName('sidebar')[0] &&
      document.getElementsByClassName('sidebar')[0].hidden !== true
    ) {
      document.getElementsByClassName('sidebar')[0].hidden = true;
    }
    if (!this.props.currentOrganisation) {
      return <Redirect to={this.state.redirect} />;
    }
    console.log(this.props);
    return (
      <>
        <div
          style={{
            width: '287mm',
            fontFamily: 'Time-New-Romans',
            transform: 'scale(100%)',
            margin: '50px auto 0',
          }}
        >
          <table
            className="MsoTableGrid"
            border={1}
            cellSpacing={0}
            cellPadding={0}
            style={{
              borderCollapse: 'collapse',
              border: 'none',
              msoBorderAlt: 'solid windowtext .5pt',
              msoYftiTbllook: 1184,
              msoPaddingAlt: '0cm 0cm 0cm 0cm',
              margin: '0 auto',
            }}
          >
            <tbody>
              <tr
                style={{
                  msoYftiIrow: 0,
                  msoYftiFirstrow: 'yes',
                  msoYftiLastrow: 'yes',
                  // height: "559.1pt"
                }}
              >
                <td
                  width={519}
                  valign="top"
                  style={{
                    width: '389.6pt',
                    border: 'solid windowtext 1.0pt',
                    msoBorderAlt: 'solid windowtext .5pt',
                    padding: '0cm 0cm 0cm 0cm',
                    verticalAlign: 'top',
                    // height: "559.1pt"
                  }}
                >
                  <table
                    className="MsoTableGrid"
                    border={1}
                    cellSpacing={0}
                    cellPadding={0}
                    style={{
                      borderCollapse: 'collapse',
                      border: 'none',
                      msoBorderAlt: 'solid windowtext .5pt',
                      msoYftiTbllook: 1184,
                      msoPaddingAlt: '0cm 5.4pt 0cm 5.4pt',
                    }}
                  >
                    <tbody>
                      <tr
                        style={{
                          msoYftiIrow: 0,
                          msoYftiFirstrow: 'yes',
                          height: '1.0cm',
                        }}
                      >
                        <td
                          width={235}
                          colSpan={2}
                          style={{
                            width: '176.05pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderBottomAlt: 'solid windowtext .5pt',
                            msoBorderRightAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '1.0cm',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              Вид спорту
                            </span>
                          </p>
                        </td>
                        <td
                          width={142}
                          style={{
                            width: '106.3pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderLeftAlt: 'solid windowtext .5pt',
                            msoBorderBottomAlt: 'solid windowtext .5pt',
                            msoBorderRightAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '1.0cm',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              {this.props.currentApplication.sportTitle}
                            </span>
                          </p>
                        </td>
                        <td
                          width={142}
                          rowSpan={4}
                          style={{
                            height: '40mm',
                            width: '30mm',
                            border: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            msoBorderLeftAlt: 'solid windowtext .5pt',
                            msoBorderBottomAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                          }}
                        >
                          {this.props.documents &&
                            this.props.documents.length > 0 &&
                            this.props.documents.map((el) => {
                              if (el.type === 5) {
                                return (
                                  <div
                                    style={{
                                      backgroundImage: `url(${el.url})`,
                                      height: '100%',
                                      width: '100%',
                                      backgroundSize: 'cover',
                                      backgroundRepeat: 'no-repeat',
                                    }}
                                  >
                                    {/* <img style={{'max-width':'95%','max-height':'95%','margin':'0 auto'}}  src={el.url} alt=""/>  */}
                                  </div>
                                );
                              }
                            })}
                        </td>
                      </tr>
                      <tr style={{ msoYftiIrow: 1, height: '1.0cm', border: 'none' }}>
                        <td
                          width={235}
                          colSpan={2}
                          style={{
                            width: '176.05pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderBottomAlt: 'solid windowtext .5pt',
                            msoBorderRightAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '1.0cm',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              Прізвище
                            </span>
                          </p>
                        </td>
                        <td
                          width={142}
                          style={{
                            width: '106.3pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderLeftAlt: 'solid windowtext .5pt',
                            msoBorderAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '1.0cm',
                          }}
                        >
                          <p className="MsoNormal">
                            <span className="SpellE">
                              <span
                                style={{
                                  fontSize: '10.0pt',
                                  fontFamily: '"Times New Roman",serif',
                                }}
                              >
                                {this.props.currentApplication.athleteLastName}
                              </span>
                            </span>
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            ></span>
                          </p>
                        </td>
                      </tr>
                      <tr style={{ msoYftiIrow: 2, height: '1.0cm', border: 'none' }}>
                        <td
                          width={235}
                          colSpan={2}
                          style={{
                            width: '176.05pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderBottomAlt: 'solid windowtext .5pt',
                            msoBorderRightAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '1.0cm',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              Власне ім'я
                            </span>
                          </p>
                        </td>
                        <td
                          width={142}
                          style={{
                            width: '106.3pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderLeftAlt: 'solid windowtext .5pt',
                            msoBorderAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '1.0cm',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              {this.props.currentApplication.athleteFirstName}
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr style={{ msoYftiIrow: 3, height: '1.0cm', border: 'none' }}>
                        <td
                          width={235}
                          colSpan={2}
                          style={{
                            width: '176.05pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderBottomAlt: 'solid windowtext .5pt',
                            msoBorderRightAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '1.0cm',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              По батькові (за наявності)
                            </span>
                          </p>
                        </td>
                        <td
                          width={142}
                          style={{
                            width: '106.3pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderLeftAlt: 'solid windowtext .5pt',
                            msoBorderAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '1.0cm',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              {this.props.currentApplication.athleteSecondName}
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr style={{ msoYftiIrow: 4, height: '1.0cm', border: 'none' }}>
                        <td
                          width={376}
                          colSpan={3}
                          style={{
                            width: '282.35pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderBottomAlt: 'solid windowtext .5pt',
                            msoBorderRightAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '1.0cm',
                          }}
                        >
                          <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}>
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              Дата народження
                            </span>
                          </p>
                        </td>
                        {this.props.currentApplication.athleteCategoryTitle ===
                          'Заслужений тренер України/ЗТУ' && (
                          <td
                            width={142}
                            rowSpan={3}
                            style={{
                              width: '106.25pt',
                              border: 'none',
                              borderBottom: 'solid windowtext 1.0pt',
                              msoBorderTopAlt: 'solid windowtext .5pt',
                              msoBorderLeftAlt: 'solid windowtext .5pt',
                              msoBorderBottomAlt: 'solid windowtext .5pt',
                              padding: '0cm 5.4pt 0cm 5.4pt',
                              height: '1.0cm',
                            }}
                          >
                            <p className="MsoNormal">
                              <span
                                style={{
                                  fontSize: '10.0pt',
                                  fontFamily: '"Times New Roman",serif',
                                }}
                              >
                                Стаж тренерської роботи
                              </span>
                            </p>
                            <p className="MsoNormal">
                              <span
                                style={{
                                  fontSize: '10.0pt',
                                  fontFamily: '"Times New Roman",serif',
                                }}
                              >
                                з&nbsp;
                                {new Date(
                                  this.props.currentApplication.athleteTrainerExperienceFrom,
                                ).toLocaleString('uk-UA', {
                                  day: 'numeric',
                                  month: 'numeric',
                                  year: 'numeric',
                                })}
                              </span>
                            </p>
                            <p className="MsoNormal">
                              <span
                                style={{
                                  fontSize: '10.0pt',
                                  fontFamily: '"Times New Roman",serif',
                                }}
                              ></span>
                            </p>
                            <p className="MsoNormal">
                              <span
                                style={{
                                  fontSize: '10.0pt',
                                  fontFamily: '"Times New Roman",serif',
                                }}
                              >
                                по&nbsp;{' '}
                                {new Date(
                                  this.props.currentApplication.athleteTrainerExperienceTo,
                                ).toLocaleString('uk-UA', {
                                  day: 'numeric',
                                  month: 'numeric',
                                  year: 'numeric',
                                })}
                              </span>
                            </p>
                          </td>
                        )}
                      </tr>
                      <tr style={{ msoYftiIrow: 5, height: '17.5pt' }}>
                        <td
                          width={93}
                          style={{
                            width: '69.7pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderBottomAlt: 'solid windowtext .5pt',
                            msoBorderRightAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '17.5pt',
                          }}
                        >
                          <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}>
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              число
                            </span>
                          </p>
                        </td>
                        <td
                          width={142}
                          style={{
                            width: '106.35pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderLeftAlt: 'solid windowtext .5pt',
                            msoBorderAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '17.5pt',
                          }}
                        >
                          <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}>
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              місяць
                            </span>
                          </p>
                        </td>
                        <td
                          width={142}
                          style={{
                            width: '106.3pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderLeftAlt: 'solid windowtext .5pt',
                            msoBorderAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '17.5pt',
                          }}
                        >
                          <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}>
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              рік
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr style={{ msoYftiIrow: 6, height: '13.45pt' }}>
                        <td
                          width={93}
                          style={{
                            width: '69.7pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderBottomAlt: 'solid windowtext .5pt',
                            msoBorderRightAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '13.45pt',
                          }}
                        >
                          <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}>
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              {new Date(this.props.currentApplication.birthday).toLocaleString(
                                'uk-UA',
                                { day: 'numeric' },
                              )}
                            </span>
                          </p>
                        </td>
                        <td
                          width={142}
                          style={{
                            width: '106.35pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderLeftAlt: 'solid windowtext .5pt',
                            msoBorderAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '13.45pt',
                          }}
                        >
                          <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}>
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              {new Date(this.props.currentApplication.birthday).toLocaleString(
                                'uk-UA',
                                { month: 'numeric' },
                              )}
                            </span>
                          </p>
                        </td>
                        <td
                          width={142}
                          style={{
                            width: '106.3pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderLeftAlt: 'solid windowtext .5pt',
                            msoBorderAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '13.45pt',
                          }}
                        >
                          <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}>
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              {new Date(this.props.currentApplication.birthday).toLocaleString(
                                'uk-UA',
                                { year: 'numeric' },
                              )}
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr style={{ msoYftiIrow: 7, height: '1.0cm', border: 'none' }}>
                        <td
                          width={235}
                          colSpan={2}
                          style={{
                            width: '176.05pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderBottomAlt: 'solid windowtext .5pt',
                            msoBorderRightAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '1.0cm',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              Місце проживання на час надання
                            </span>
                          </p>
                        </td>
                        <td
                          width={283}
                          colSpan={2}
                          style={{
                            width: '212.55pt',
                            border: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderLeftAlt: 'solid windowtext .5pt',
                            msoBorderBottomAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '1.0cm',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              {this.props.currentApplication.address}
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr style={{ msoYftiIrow: 8, height: '34.65pt' }}>
                        <td
                          width={235}
                          colSpan={2}
                          style={{
                            width: '176.05pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderBottomAlt: 'solid windowtext .5pt',
                            msoBorderRightAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '34.65pt',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              Фізкультурно-спортивне товариство, інший суб'єкт сфери фізичної
                              культури і спорту
                            </span>
                          </p>
                        </td>
                        <td
                          width={283}
                          colSpan={2}
                          style={{
                            width: '212.55pt',
                            border: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderLeftAlt: 'solid windowtext .5pt',
                            msoBorderBottomAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '34.65pt',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              {this.props.currentApplication.company}
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr style={{ msoYftiIrow: 9, height: '15.6pt' }}>
                        <td
                          width={235}
                          colSpan={2}
                          style={{
                            width: '176.05pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderBottomAlt: 'solid windowtext .5pt',
                            msoBorderRightAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '15.6pt',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              Освіта
                            </span>
                          </p>
                        </td>
                        <td
                          width={283}
                          colSpan={2}
                          style={{
                            width: '212.55pt',
                            border: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderLeftAlt: 'solid windowtext .5pt',
                            msoBorderBottomAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '15.6pt',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              {this.props.currentApplication.education}
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr style={{ msoYftiIrow: 10, height: '19.1pt' }}>
                        <td
                          width={235}
                          colSpan={2}
                          style={{
                            width: '176.05pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderBottomAlt: 'solid windowtext .5pt',
                            msoBorderRightAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '19.1pt',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              Місце роботи (навчання), посада
                            </span>
                          </p>
                        </td>
                        <td
                          width={283}
                          colSpan={2}
                          style={{
                            width: '212.55pt',
                            border: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderLeftAlt: 'solid windowtext .5pt',
                            msoBorderBottomAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '19.1pt',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              {this.props.currentApplication.workPlace}
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr style={{ msoYftiIrow: 11, height: '23.65pt' }}>
                        <td
                          width={235}
                          colSpan={2}
                          style={{
                            width: '176.05pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderBottomAlt: 'solid windowtext .5pt',
                            msoBorderRightAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '23.65pt',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              Попереднє звання та дата присвоєння
                            </span>
                          </p>
                        </td>
                        <td
                          width={283}
                          colSpan={2}
                          style={{
                            width: '212.55pt',
                            border: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderLeftAlt: 'solid windowtext .5pt',
                            msoBorderBottomAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '23.65pt',
                          }}
                        >
                          <span
                            style={{
                              fontSize: '10.0pt',
                              fontFamily: '"Times New Roman",serif',
                            }}
                          >
                            {this.props.currentApplication.previousSportCategoryTitle + ' '}
                            {this.props.currentApplication.previousSportCategoryDate &&
                              new Date(
                                this.props.currentApplication.previousSportCategoryDate,
                              ).toLocaleString('uk-UA', {
                                day: 'numeric',
                                month: 'numeric',
                                year: 'numeric',
                              })}
                          </span>
                        </td>
                      </tr>
                      {
                        <>
                          <tr style={{ msoYftiIrow: 12, height: '1.0cm', border: 'none' }}>
                            <td
                              width={235}
                              colSpan={2}
                              style={{
                                width: '176.05pt',
                                borderTop: 'none',
                                borderLeft: 'none',
                                borderBottom: 'solid windowtext 1.0pt',
                                borderRight: 'solid windowtext 1.0pt',
                                msoBorderTopAlt: 'solid windowtext .5pt',
                                msoBorderBottomAlt: 'solid windowtext .5pt',
                                msoBorderRightAlt: 'solid windowtext .5pt',
                                padding: '0cm 5.4pt 0cm 5.4pt',
                                height: '1.0cm',
                              }}
                            >
                              <p
                                className="MsoNormal"
                                align="center"
                                style={{ textAlign: 'center' }}
                              >
                                <span
                                  style={{
                                    fontSize: '10.0pt',
                                    fontFamily: '"Times New Roman",serif',
                                  }}
                                >
                                  Власне ім'я ПРІЗВИЩЕ тренера, що підготував спортсмена
                                </span>
                              </p>
                            </td>
                            <td
                              width={283}
                              colSpan={2}
                              style={{
                                width: '212.55pt',
                                border: 'none',
                                borderBottom: 'solid windowtext 1.0pt',
                                msoBorderTopAlt: 'solid windowtext .5pt',
                                msoBorderLeftAlt: 'solid windowtext .5pt',
                                msoBorderBottomAlt: 'solid windowtext .5pt',
                                padding: '0cm 5.4pt 0cm 5.4pt',
                                height: '1.0cm',
                              }}
                            >
                              <p
                                className="MsoNormal"
                                align="center"
                                style={{ textAlign: 'center' }}
                              >
                                <span
                                  style={{
                                    fontSize: '10.0pt',
                                    fontFamily: '"Times New Roman",serif',
                                  }}
                                >
                                  Тренерська категорія
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ msoYftiIrow: 13, height: '19.6pt' }}>
                            <td
                              width={235}
                              colSpan={2}
                              style={{
                                width: '176.05pt',
                                borderTop: 'none',
                                borderLeft: 'none',
                                borderBottom: 'solid windowtext 1.0pt',
                                borderRight: 'solid windowtext 1.0pt',
                                msoBorderTopAlt: 'solid windowtext .5pt',
                                msoBorderBottomAlt: 'solid windowtext .5pt',
                                msoBorderRightAlt: 'solid windowtext .5pt',
                                padding: '0cm 5.4pt 0cm 5.4pt',
                                height: '19.6pt',
                              }}
                            >
                              <p className="MsoNormal">
                                <span
                                  style={{
                                    fontSize: '10.0pt',
                                    fontFamily: '"Times New Roman",serif',
                                  }}
                                >
                                  {this.props.currentApplication.trainerFirstNameFirst + ' '}
                                  {this.props.currentApplication.trainerLastNameFirst.toUpperCase()}
                                </span>
                              </p>
                            </td>
                            <td
                              width={283}
                              colSpan={2}
                              style={{
                                width: '212.55pt',
                                border: 'none',
                                borderBottom: 'solid windowtext 1.0pt',
                                msoBorderTopAlt: 'solid windowtext .5pt',
                                msoBorderLeftAlt: 'solid windowtext .5pt',
                                msoBorderBottomAlt: 'solid windowtext .5pt',
                                padding: '0cm 5.4pt 0cm 5.4pt',
                                height: '19.6pt',
                              }}
                            >
                              <p className="MsoNormal">
                                <span
                                  style={{
                                    fontSize: '10.0pt',
                                    fontFamily: '"Times New Roman",serif',
                                  }}
                                >
                                  {this.props.currentApplication.trainerCategoryFirst}
                                </span>
                              </p>
                            </td>
                          </tr>
                          {this.props.currentApplication.trainerCategorySecond &&
                            this.props.currentApplication.trainerCategorySecond.length > 0 && (
                              <tr style={{ msoYftiIrow: 14, height: '21.15pt' }}>
                                <td
                                  width={235}
                                  colSpan={2}
                                  style={{
                                    width: '176.05pt',
                                    borderTop: 'none',
                                    borderLeft: 'none',
                                    borderBottom: 'solid windowtext 1.0pt',
                                    borderRight: 'solid windowtext 1.0pt',
                                    msoBorderTopAlt: 'solid windowtext .5pt',
                                    msoBorderBottomAlt: 'solid windowtext .5pt',
                                    msoBorderRightAlt: 'solid windowtext .5pt',
                                    padding: '0cm 5.4pt 0cm 5.4pt',
                                    height: '21.15pt',
                                  }}
                                >
                                  <p className="MsoNormal">
                                    <span
                                      style={{
                                        fontSize: '10.0pt',
                                        fontFamily: '"Times New Roman",serif',
                                      }}
                                    >
                                      {this.props.currentApplication.trainerFirstNameSecond + ' '}
                                      {this.props.currentApplication.trainerLastNameSecond?.toUpperCase()}
                                    </span>
                                  </p>
                                </td>
                                <td
                                  width={283}
                                  colSpan={2}
                                  style={{
                                    width: '212.55pt',
                                    border: 'none',
                                    borderBottom: 'solid windowtext 1.0pt',
                                    msoBorderTopAlt: 'solid windowtext .5pt',
                                    msoBorderLeftAlt: 'solid windowtext .5pt',
                                    msoBorderBottomAlt: 'solid windowtext .5pt',
                                    padding: '0cm 5.4pt 0cm 5.4pt',
                                    height: '21.15pt',
                                  }}
                                >
                                  <p className="MsoNormal">
                                    <span
                                      style={{
                                        fontSize: '10.0pt',
                                        fontFamily: '"Times New Roman",serif',
                                      }}
                                    >
                                      {this.props.currentApplication.trainerCategorySecond}
                                    </span>
                                  </p>
                                </td>
                              </tr>
                            )}
                          {this.props.currentApplication.trainerCategoryThird &&
                            this.props.currentApplication.trainerCategoryThird.length > 0 && (
                              <tr style={{ msoYftiIrow: 14, height: '21.15pt' }}>
                                <td
                                  width={235}
                                  colSpan={2}
                                  style={{
                                    width: '176.05pt',
                                    borderTop: 'none',
                                    borderLeft: 'none',
                                    borderBottom: 'solid windowtext 1.0pt',
                                    borderRight: 'solid windowtext 1.0pt',
                                    msoBorderTopAlt: 'solid windowtext .5pt',
                                    msoBorderBottomAlt: 'solid windowtext .5pt',
                                    msoBorderRightAlt: 'solid windowtext .5pt',
                                    padding: '0cm 5.4pt 0cm 5.4pt',
                                    height: '21.15pt',
                                  }}
                                >
                                  <p className="MsoNormal">
                                    <span
                                      style={{
                                        fontSize: '10.0pt',
                                        fontFamily: '"Times New Roman",serif',
                                      }}
                                    >
                                      {this.props.currentApplication.trainerFirstNameThird + ' '}{' '}
                                      {this.props.currentApplication.trainerLastNameThird?.toUpperCase() +
                                        ' '}
                                    </span>
                                  </p>
                                </td>
                                <td
                                  width={283}
                                  colSpan={2}
                                  style={{
                                    width: '212.55pt',
                                    border: 'none',
                                    borderBottom: 'solid windowtext 1.0pt',
                                    msoBorderTopAlt: 'solid windowtext .5pt',
                                    msoBorderLeftAlt: 'solid windowtext .5pt',
                                    msoBorderBottomAlt: 'solid windowtext .5pt',
                                    padding: '0cm 5.4pt 0cm 5.4pt',
                                    height: '21.15pt',
                                  }}
                                >
                                  <p className="MsoNormal">
                                    <span
                                      style={{
                                        fontSize: '10.0pt',
                                        fontFamily: '"Times New Roman",serif',
                                      }}
                                    >
                                      {this.props.currentApplication.trainerCategoryThird}
                                    </span>
                                  </p>
                                </td>
                              </tr>
                            )}
                        </>
                      }
                      <tr style={{ msoYftiIrow: 15, height: '1.0cm', border: 'none' }}>
                        <td
                          width={518}
                          colSpan={4}
                          style={{
                            width: '388.6pt',
                            border: 'none',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '1.0cm',
                          }}
                        >
                          <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}>
                            <b>
                              <span
                                style={{
                                  fontSize: '10.0pt',
                                  fontFamily: '"Times New Roman",serif',
                                }}
                              >
                                ВИСНОВОК
                              </span>
                            </b>
                          </p>
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              Структурного підрозділу Мінмолодьспорту, до повноважень якого
                              віднесено координацію розвитку виду спорту: матеріали відповідають /
                              не відповідають вимогам
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr style={{ msoYftiIrow: 16, height: '1.0cm', border: 'none' }}>
                        <td
                          width={235}
                          colSpan={2}
                          style={{
                            width: '176.05pt',
                            border: 'none',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '1.0cm',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              Відповідальний виконавець
                            </span>
                          </p>
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              з виду спорту
                            </span>
                          </p>
                        </td>
                        <td
                          width={142}
                          valign="bottom"
                          style={{
                            width: '106.3pt',
                            verticalAlign: 'bottom',
                            border: 'none',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '1.0cm',
                          }}
                        >
                          <div
                            valign="bottom"
                            style={{
                              msoElement: 'para-border-div',
                              border: 'none',
                              borderBottom: 'solid windowtext 1.5pt',
                              padding: '0cm 0cm 1.0pt 0cm',
                            }}
                          >
                            <p
                              className="MsoNormal"
                              align="center"
                              style={{
                                textAlign: 'center',
                                border: 'none',
                                msoBorderBottomAlt: 'solid windowtext 1.5pt',
                                padding: '0cm',
                                msoPaddingAlt: '0cm 0cm 1.0pt 0cm',
                              }}
                            >
                              <span
                                style={{
                                  fontSize: '10.0pt',
                                  fontFamily: '"Times New Roman",serif',
                                }}
                              ></span>
                            </p>
                          </div>
                          <p className="MsoNormal" align="bottom" style={{ textAlign: 'center' }}>
                            <span
                              style={{
                                fontSize: '8.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              (підпис)
                            </span>
                          </p>
                        </td>
                        <td
                          width={142}
                          valign="bottom"
                          style={{
                            width: '107.25pt',
                            verticalAlign: 'bottom',
                            border: 'none',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '1.0cm',
                          }}
                        >
                          <div
                            style={{
                              msoElement: 'para-border-div',
                              border: 'none',
                              borderBottom: 'solid windowtext 1.5pt',
                              padding: '0cm 0cm 1.0pt 0cm',
                            }}
                          >
                            <p
                              className="MsoNormal"
                              align="bottom"
                              style={{
                                textAlign: 'center',
                                border: 'none',
                                msoBorderBottomAlt: 'solid windowtext 1.5pt',
                                padding: '0cm',
                                msoPaddingAlt: '0cm 0cm 1.0pt 0cm',
                              }}
                            >
                              <span
                                style={{
                                  fontSize: '10.0pt',
                                  fontFamily: '"Times New Roman",serif',
                                }}
                              ></span>
                            </p>
                          </div>
                          <p className="MsoNormal" align="bottom" style={{ textAlign: 'center' }}>
                            <span
                              style={{
                                fontSize: '8.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              (власне ім'я ПРІЗВИЩЕ)
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr style={{ msoYftiIrow: 17, height: '18.85pt' }}>
                        <td
                          width={235}
                          colSpan={2}
                          style={{
                            width: '176.05pt',
                            border: 'none',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '18.85pt',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              Керівник структурного підрозділу Мінмолодьспорту до повноважень якого
                              віднесено координацію розвитку виду спорту
                            </span>
                          </p>
                        </td>
                        <td
                          width={142}
                          valign="bottom"
                          style={{
                            width: '106.3pt',
                            verticalAlign: 'bottom',
                            border: 'none',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '18.85pt',
                          }}
                        >
                          <div
                            style={{
                              msoElement: 'para-border-div',
                              border: 'none',
                              borderBottom: 'solid windowtext 1.5pt',
                              padding: '0cm 0cm 1.0pt 0cm',
                            }}
                          >
                            <p
                              className="MsoNormal"
                              align="bottom"
                              style={{
                                textAlign: 'center',
                                verticalAlign: 'bottom',
                                border: 'none',
                                msoBorderBottomAlt: 'solid windowtext 1.5pt',
                                padding: '0cm',
                                msoPaddingAlt: '0cm 0cm 1.0pt 0cm',
                              }}
                            >
                              <span
                                style={{
                                  fontSize: '10.0pt',
                                  fontFamily: '"Times New Roman",serif',
                                }}
                              ></span>
                            </p>
                          </div>
                          <p className="MsoNormal" align="bottom" style={{ textAlign: 'center' }}>
                            <span
                              style={{
                                fontSize: '8.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              (підпис)
                            </span>
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            ></span>
                          </p>
                        </td>
                        <td
                          width={142}
                          valign="bottom"
                          style={{
                            width: '107.25pt',
                            verticalAlign: 'bottom',
                            border: 'none',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '18.85pt',
                          }}
                        >
                          <div
                            style={{
                              msoElement: 'para-border-div',
                              border: 'none',
                              borderBottom: 'solid windowtext 1.5pt',
                              padding: '0cm 0cm 1.0pt 0cm',
                            }}
                          >
                            <p
                              className="MsoNormal"
                              align="bottom"
                              style={{
                                textAlign: 'center',
                                border: 'none',
                                msoBorderBottomAlt: 'solid windowtext 1.5pt',
                                padding: '0cm',
                                msoPaddingAlt: '0cm 0cm 1.0pt 0cm',
                              }}
                            >
                              <span
                                style={{
                                  fontSize: '10.0pt',
                                  fontFamily: '"Times New Roman",serif',
                                }}
                              ></span>
                            </p>
                          </div>
                          <p className="MsoNormal" align="bottom" style={{ textAlign: 'center' }}>
                            <span
                              style={{
                                fontSize: '8.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              (власне ім'я ПРІЗВИЩЕ)
                            </span>
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            ></span>
                          </p>
                        </td>
                      </tr>
                      <tr
                        style={{
                          msoYftiIrow: 18,
                          msoYftiLastrow: 'yes',
                          height: '20.85pt',
                        }}
                      >
                        <td
                          width={235}
                          colSpan={2}
                          valign="bottom"
                          style={{
                            width: '176.05pt',
                            border: 'none',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '20.85pt',
                          }}
                        >
                          <p className="MsoNormal" align="bottom" style={{ textAlign: 'center' }}>
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            ></span>
                          </p>
                          <p className="MsoNormal" align="bottom" style={{ textAlign: 'center' }}>
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              _________________________________
                            </span>
                          </p>
                          <p className="MsoNormal" align="bottm" style={{ textAlign: 'center' }}>
                            <span
                              style={{
                                fontSize: '8.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              (дата)
                            </span>
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            ></span>
                          </p>
                        </td>
                        <td
                          width={142}
                          style={{
                            width: '106.3pt',
                            border: 'none',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '20.85pt',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            ></span>
                          </p>
                        </td>
                        <td
                          width={142}
                          style={{
                            width: '106.25pt',
                            verticalAlign: 'bottom',
                            border: 'none',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '20.85pt',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            ></span>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p className="MsoNormal">
                    <span
                      lang="UK"
                      style={{
                        fontSize: '11.0pt',
                        fontFamily: '"Times New Roman",serif',
                        msoAnsiLanguage: 'UK',
                      }}
                    ></span>
                  </p>
                </td>
                <td
                  width={508}
                  valign="top"
                  style={{
                    width: '380.95pt',
                    border: 'solid windowtext 1.0pt',
                    borderLeft: 'none',
                    msoBorderLeftAlt: 'solid windowtext .5pt',
                    msoBorderAlt: 'solid windowtext .5pt',
                    padding: '0cm 0cm 0cm 0cm',
                    // height: "559.1pt"
                  }}
                >
                  <table
                    className="MsoTableGrid"
                    border={1}
                    cellSpacing={0}
                    cellPadding={0}
                    style={{
                      borderCollapse: 'collapse',
                      border: 'none',
                      msoBorderAlt: 'solid windowtext .5pt',
                      msoYftiTbllook: 1184,
                      msoPaddingAlt: '0cm 5.4pt 0cm 5.4pt',
                    }}
                  >
                    <tbody>
                      <tr
                        style={{
                          msoYftiIrow: 0,
                          msoYftiFirstrow: 'yes',
                          height: '19.75pt',
                        }}
                      >
                        <td
                          width={507}
                          colSpan={4}
                          style={{
                            width: '379.95pt',
                            border: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            msoBorderBottomAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '19.75pt',
                          }}
                        >
                          <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}>
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              ОСНОВНІ ПОКАЗНИКИ
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr style={{ msoYftiIrow: 1, height: '43.3pt' }}>
                        <td
                          width={131}
                          style={{
                            width: '98.6pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderBottomAlt: 'solid windowtext .5pt',
                            msoBorderRightAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '43.3pt',
                          }}
                        >
                          <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}>
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              Дата виконання
                            </span>
                          </p>
                          <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}>
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              Число, місяць, рік
                            </span>
                          </p>
                        </td>
                        <td
                          width={161}
                          style={{
                            width: '120.45pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderLeftAlt: 'solid windowtext .5pt',
                            msoBorderAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '43.3pt',
                          }}
                        >
                          <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}>
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              Назва змагань
                            </span>
                          </p>
                        </td>
                        <td
                          width={104}
                          style={{
                            width: '78.0pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderLeftAlt: 'solid windowtext .5pt',
                            msoBorderAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '43.3pt',
                          }}
                        >
                          <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}>
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              Ранг, категорія змагань
                            </span>
                          </p>
                        </td>
                        <td
                          width={111}
                          style={{
                            width: '82.9pt',
                            border: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderLeftAlt: 'solid windowtext .5pt',
                            msoBorderBottomAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '43.3pt',
                          }}
                        >
                          <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}>
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              Виконаний результат
                            </span>
                          </p>
                        </td>
                      </tr>

                      {this.props.currentApplication.achievements &&
                        this.props.currentApplication.achievements.length > 0 &&
                        this.props.currentApplication.achievements.map((el) => {
                          return (
                            <tr style={{ msoYftiIrow: 2, height: '43.7pt' }}>
                              <td
                                width={131}
                                style={{
                                  width: '98.6pt',
                                  borderTop: 'none',
                                  borderLeft: 'none',
                                  borderBottom: 'solid windowtext 1.0pt',
                                  borderRight: 'solid windowtext 1.0pt',
                                  msoBorderTopAlt: 'solid windowtext .5pt',
                                  msoBorderBottomAlt: 'solid windowtext .5pt',
                                  msoBorderRightAlt: 'solid windowtext .5pt',
                                  padding: '0cm 5.4pt 0cm 5.4pt',
                                  height: '43.7pt',
                                }}
                              >
                                <p className="MsoNormal">
                                  <span
                                    style={{
                                      fontSize: '10.0pt',
                                      fontFamily: '"Times New Roman",serif',
                                    }}
                                  >
                                    {el.eventDateStart &&
                                      new Date(el.eventDateStart).toLocaleString('uk-UA', {
                                        day: 'numeric',
                                        month: 'numeric',
                                        year: 'numeric',
                                      })}{' '}
                                    –{' '}
                                    {el.eventDateEnd &&
                                      new Date(el.eventDateEnd).toLocaleString('uk-UA', {
                                        day: 'numeric',
                                        month: 'numeric',
                                        year: 'numeric',
                                      })}
                                  </span>
                                </p>
                              </td>
                              <td
                                width={161}
                                style={{
                                  width: '120.45pt',
                                  borderTop: 'none',
                                  borderLeft: 'none',
                                  borderBottom: 'solid windowtext 1.0pt',
                                  borderRight: 'solid windowtext 1.0pt',
                                  msoBorderTopAlt: 'solid windowtext .5pt',
                                  msoBorderLeftAlt: 'solid windowtext .5pt',
                                  msoBorderAlt: 'solid windowtext .5pt',
                                  padding: '0cm 5.4pt 0cm 5.4pt',
                                  height: '43.7pt',
                                }}
                              >
                                <p className="MsoNormal">
                                  <span
                                    style={{
                                      fontSize: '10.0pt',
                                      fontFamily: '"Times New Roman",serif',
                                    }}
                                  >
                                    {el.eventTitle}
                                  </span>
                                </p>
                              </td>
                              <td
                                width={104}
                                style={{
                                  width: '78.0pt',
                                  borderTop: 'none',
                                  borderLeft: 'none',
                                  borderBottom: 'solid windowtext 1.0pt',
                                  borderRight: 'solid windowtext 1.0pt',
                                  msoBorderTopAlt: 'solid windowtext .5pt',
                                  msoBorderLeftAlt: 'solid windowtext .5pt',
                                  msoBorderAlt: 'solid windowtext .5pt',
                                  padding: '0cm 5.4pt 0cm 5.4pt',
                                  height: '43.7pt',
                                }}
                              >
                                <p className="MsoNormal">
                                  <span
                                    style={{
                                      fontSize: '10.0pt',
                                      fontFamily: '"Times New Roman",serif',
                                    }}
                                  >
                                    {this.props.fullList &&
                                      this.props.fullList[6] &&
                                      this.props.fullList[6].map((elem) => {
                                        if (elem.id === el.eventCategory) return <>{elem.title}</>;
                                      })}
                                  </span>
                                </p>
                              </td>
                              <td
                                width={111}
                                style={{
                                  width: '82.9pt',
                                  border: 'none',
                                  borderBottom: 'solid windowtext 1.0pt',
                                  msoBorderTopAlt: 'solid windowtext .5pt',
                                  msoBorderLeftAlt: 'solid windowtext .5pt',
                                  msoBorderBottomAlt: 'solid windowtext .5pt',
                                  padding: '0cm 5.4pt 0cm 5.4pt',
                                  height: '43.7pt',
                                }}
                              >
                                <p className="MsoNormal">
                                  <span
                                    style={{
                                      fontSize: '10.0pt',
                                      fontFamily: '"Times New Roman",serif',
                                    }}
                                  >
                                    {el.eventResult}
                                  </span>
                                </p>
                              </td>
                            </tr>
                          );
                        })}
                      {this.props.currentApplication.achievements.length < 6 && (
                        <tr style={{ msoYftiIrow: 3, height: '42.25pt' }}>
                          <td
                            width={131}
                            style={{
                              width: '98.6pt',
                              borderTop: 'none',
                              borderLeft: 'none',
                              borderBottom: 'solid windowtext 1.0pt',
                              borderRight: 'solid windowtext 1.0pt',
                              msoBorderTopAlt: 'solid windowtext .5pt',
                              msoBorderBottomAlt: 'solid windowtext .5pt',
                              msoBorderRightAlt: 'solid windowtext .5pt',
                              padding: '0cm 5.4pt 0cm 5.4pt',
                              height: '42.25pt',
                            }}
                          >
                            <p className="MsoNormal">
                              <span
                                style={{
                                  fontSize: '10.0pt',
                                  fontFamily: '"Times New Roman",serif',
                                }}
                              ></span>
                            </p>
                          </td>
                          <td
                            width={161}
                            style={{
                              width: '120.45pt',
                              borderTop: 'none',
                              borderLeft: 'none',
                              borderBottom: 'solid windowtext 1.0pt',
                              borderRight: 'solid windowtext 1.0pt',
                              msoBorderTopAlt: 'solid windowtext .5pt',
                              msoBorderLeftAlt: 'solid windowtext .5pt',
                              msoBorderAlt: 'solid windowtext .5pt',
                              padding: '0cm 5.4pt 0cm 5.4pt',
                              height: '42.25pt',
                            }}
                          >
                            <p className="MsoNormal">
                              <span
                                style={{
                                  fontSize: '10.0pt',
                                  fontFamily: '"Times New Roman",serif',
                                }}
                              ></span>
                            </p>
                          </td>
                          <td
                            width={104}
                            style={{
                              width: '78.0pt',
                              borderTop: 'none',
                              borderLeft: 'none',
                              borderBottom: 'solid windowtext 1.0pt',
                              borderRight: 'solid windowtext 1.0pt',
                              msoBorderTopAlt: 'solid windowtext .5pt',
                              msoBorderLeftAlt: 'solid windowtext .5pt',
                              msoBorderAlt: 'solid windowtext .5pt',
                              padding: '0cm 5.4pt 0cm 5.4pt',
                              height: '42.25pt',
                            }}
                          >
                            <p className="MsoNormal">
                              <span
                                style={{
                                  fontSize: '10.0pt',
                                  fontFamily: '"Times New Roman",serif',
                                }}
                              ></span>
                            </p>
                          </td>
                          <td
                            width={111}
                            style={{
                              width: '82.9pt',
                              border: 'none',
                              borderBottom: 'solid windowtext 1.0pt',
                              msoBorderTopAlt: 'solid windowtext .5pt',
                              msoBorderLeftAlt: 'solid windowtext .5pt',
                              msoBorderBottomAlt: 'solid windowtext .5pt',
                              padding: '0cm 5.4pt 0cm 5.4pt',
                              height: '42.25pt',
                            }}
                          >
                            <p className="MsoNormal">
                              <span
                                style={{
                                  fontSize: '10.0pt',
                                  fontFamily: '"Times New Roman",serif',
                                }}
                              ></span>
                            </p>
                          </td>
                        </tr>
                      )}
                      {this.props.currentApplication.achievements.length < 5 && (
                        <tr style={{ msoYftiIrow: 4, height: '42.1pt' }}>
                          <td
                            width={131}
                            style={{
                              width: '98.6pt',
                              borderTop: 'none',
                              borderLeft: 'none',
                              borderBottom: 'solid windowtext 1.0pt',
                              borderRight: 'solid windowtext 1.0pt',
                              msoBorderTopAlt: 'solid windowtext .5pt',
                              msoBorderBottomAlt: 'solid windowtext .5pt',
                              msoBorderRightAlt: 'solid windowtext .5pt',
                              padding: '0cm 5.4pt 0cm 5.4pt',
                              height: '42.1pt',
                            }}
                          >
                            <p className="MsoNormal">
                              <span
                                style={{
                                  fontSize: '10.0pt',
                                  fontFamily: '"Times New Roman",serif',
                                }}
                              ></span>
                            </p>
                          </td>
                          <td
                            width={161}
                            style={{
                              width: '120.45pt',
                              borderTop: 'none',
                              borderLeft: 'none',
                              borderBottom: 'solid windowtext 1.0pt',
                              borderRight: 'solid windowtext 1.0pt',
                              msoBorderTopAlt: 'solid windowtext .5pt',
                              msoBorderLeftAlt: 'solid windowtext .5pt',
                              msoBorderAlt: 'solid windowtext .5pt',
                              padding: '0cm 5.4pt 0cm 5.4pt',
                              height: '42.1pt',
                            }}
                          >
                            <p className="MsoNormal">
                              <span
                                style={{
                                  fontSize: '10.0pt',
                                  fontFamily: '"Times New Roman",serif',
                                }}
                              ></span>
                            </p>
                          </td>
                          <td
                            width={104}
                            style={{
                              width: '78.0pt',
                              borderTop: 'none',
                              borderLeft: 'none',
                              borderBottom: 'solid windowtext 1.0pt',
                              borderRight: 'solid windowtext 1.0pt',
                              msoBorderTopAlt: 'solid windowtext .5pt',
                              msoBorderLeftAlt: 'solid windowtext .5pt',
                              msoBorderAlt: 'solid windowtext .5pt',
                              padding: '0cm 5.4pt 0cm 5.4pt',
                              height: '42.1pt',
                            }}
                          >
                            <p className="MsoNormal">
                              <span
                                style={{
                                  fontSize: '10.0pt',
                                  fontFamily: '"Times New Roman",serif',
                                }}
                              ></span>
                            </p>
                          </td>
                          <td
                            width={111}
                            style={{
                              width: '82.9pt',
                              border: 'none',
                              borderBottom: 'solid windowtext 1.0pt',
                              msoBorderTopAlt: 'solid windowtext .5pt',
                              msoBorderLeftAlt: 'solid windowtext .5pt',
                              msoBorderBottomAlt: 'solid windowtext .5pt',
                              padding: '0cm 5.4pt 0cm 5.4pt',
                              height: '42.1pt',
                            }}
                          >
                            <p className="MsoNormal">
                              <span
                                style={{
                                  fontSize: '10.0pt',
                                  fontFamily: '"Times New Roman",serif',
                                }}
                              ></span>
                            </p>
                          </td>
                        </tr>
                      )}
                      {this.props.currentApplication.achievements.length < 4 && (
                        <tr style={{ msoYftiIrow: 5, height: '40.0pt' }}>
                          <td
                            width={131}
                            style={{
                              width: '98.6pt',
                              borderTop: 'none',
                              borderLeft: 'none',
                              borderBottom: 'solid windowtext 1.0pt',
                              borderRight: 'solid windowtext 1.0pt',
                              msoBorderTopAlt: 'solid windowtext .5pt',
                              msoBorderBottomAlt: 'solid windowtext .5pt',
                              msoBorderRightAlt: 'solid windowtext .5pt',
                              padding: '0cm 5.4pt 0cm 5.4pt',
                              height: '40.0pt',
                            }}
                          >
                            <p className="MsoNormal">
                              <span
                                style={{
                                  fontSize: '10.0pt',
                                  fontFamily: '"Times New Roman",serif',
                                }}
                              ></span>
                            </p>
                          </td>
                          <td
                            width={161}
                            style={{
                              width: '120.45pt',
                              borderTop: 'none',
                              borderLeft: 'none',
                              borderBottom: 'solid windowtext 1.0pt',
                              borderRight: 'solid windowtext 1.0pt',
                              msoBorderTopAlt: 'solid windowtext .5pt',
                              msoBorderLeftAlt: 'solid windowtext .5pt',
                              msoBorderAlt: 'solid windowtext .5pt',
                              padding: '0cm 5.4pt 0cm 5.4pt',
                              height: '40.0pt',
                            }}
                          >
                            <p className="MsoNormal">
                              <span
                                style={{
                                  fontSize: '10.0pt',
                                  fontFamily: '"Times New Roman",serif',
                                }}
                              ></span>
                            </p>
                          </td>
                          <td
                            width={104}
                            style={{
                              width: '78.0pt',
                              borderTop: 'none',
                              borderLeft: 'none',
                              borderBottom: 'solid windowtext 1.0pt',
                              borderRight: 'solid windowtext 1.0pt',
                              msoBorderTopAlt: 'solid windowtext .5pt',
                              msoBorderLeftAlt: 'solid windowtext .5pt',
                              msoBorderAlt: 'solid windowtext .5pt',
                              padding: '0cm 5.4pt 0cm 5.4pt',
                              height: '40.0pt',
                            }}
                          >
                            <p className="MsoNormal">
                              <span
                                style={{
                                  fontSize: '10.0pt',
                                  fontFamily: '"Times New Roman",serif',
                                }}
                              ></span>
                            </p>
                          </td>
                          <td
                            width={111}
                            style={{
                              width: '82.9pt',
                              border: 'none',
                              borderBottom: 'solid windowtext 1.0pt',
                              msoBorderTopAlt: 'solid windowtext .5pt',
                              msoBorderLeftAlt: 'solid windowtext .5pt',
                              msoBorderBottomAlt: 'solid windowtext .5pt',
                              padding: '0cm 5.4pt 0cm 5.4pt',
                              height: '40.0pt',
                            }}
                          >
                            <p className="MsoNormal">
                              <span
                                style={{
                                  fontSize: '10.0pt',
                                  fontFamily: '"Times New Roman",serif',
                                }}
                              ></span>
                            </p>
                          </td>
                        </tr>
                      )}
                      {this.props.currentApplication.achievements.length < 3 && (
                        <tr style={{ msoYftiIrow: 6, height: '42.05pt' }}>
                          <td
                            width={131}
                            style={{
                              width: '98.6pt',
                              borderTop: 'none',
                              borderLeft: 'none',
                              borderBottom: 'solid windowtext 1.0pt',
                              borderRight: 'solid windowtext 1.0pt',
                              msoBorderTopAlt: 'solid windowtext .5pt',
                              msoBorderBottomAlt: 'solid windowtext .5pt',
                              msoBorderRightAlt: 'solid windowtext .5pt',
                              padding: '0cm 5.4pt 0cm 5.4pt',
                              height: '42.05pt',
                            }}
                          >
                            <p className="MsoNormal">
                              <span
                                style={{
                                  fontSize: '10.0pt',
                                  fontFamily: '"Times New Roman",serif',
                                }}
                              ></span>
                            </p>
                          </td>
                          <td
                            width={161}
                            style={{
                              width: '120.45pt',
                              borderTop: 'none',
                              borderLeft: 'none',
                              borderBottom: 'solid windowtext 1.0pt',
                              borderRight: 'solid windowtext 1.0pt',
                              msoBorderTopAlt: 'solid windowtext .5pt',
                              msoBorderLeftAlt: 'solid windowtext .5pt',
                              msoBorderAlt: 'solid windowtext .5pt',
                              padding: '0cm 5.4pt 0cm 5.4pt',
                              height: '42.05pt',
                            }}
                          >
                            <p className="MsoNormal">
                              <span
                                style={{
                                  fontSize: '10.0pt',
                                  fontFamily: '"Times New Roman",serif',
                                }}
                              ></span>
                            </p>
                          </td>
                          <td
                            width={104}
                            style={{
                              width: '78.0pt',
                              borderTop: 'none',
                              borderLeft: 'none',
                              borderBottom: 'solid windowtext 1.0pt',
                              borderRight: 'solid windowtext 1.0pt',
                              msoBorderTopAlt: 'solid windowtext .5pt',
                              msoBorderLeftAlt: 'solid windowtext .5pt',
                              msoBorderAlt: 'solid windowtext .5pt',
                              padding: '0cm 5.4pt 0cm 5.4pt',
                              height: '42.05pt',
                            }}
                          >
                            <p className="MsoNormal">
                              <span
                                style={{
                                  fontSize: '10.0pt',
                                  fontFamily: '"Times New Roman",serif',
                                }}
                              ></span>
                            </p>
                          </td>
                          <td
                            width={111}
                            style={{
                              width: '82.9pt',
                              border: 'none',
                              borderBottom: 'solid windowtext 1.0pt',
                              msoBorderTopAlt: 'solid windowtext .5pt',
                              msoBorderLeftAlt: 'solid windowtext .5pt',
                              msoBorderBottomAlt: 'solid windowtext .5pt',
                              padding: '0cm 5.4pt 0cm 5.4pt',
                              height: '42.05pt',
                            }}
                          >
                            <p className="MsoNormal">
                              <span
                                style={{
                                  fontSize: '10.0pt',
                                  fontFamily: '"Times New Roman",serif',
                                }}
                              ></span>
                            </p>
                          </td>
                        </tr>
                      )}
                      {this.props.currentApplication.achievements.length < 2 && (
                        <tr style={{ msoYftiIrow: 7, height: '41.3pt' }}>
                          <td
                            width={131}
                            style={{
                              width: '98.6pt',
                              borderTop: 'none',
                              borderLeft: 'none',
                              borderBottom: 'solid windowtext 1.0pt',
                              borderRight: 'solid windowtext 1.0pt',
                              msoBorderTopAlt: 'solid windowtext .5pt',
                              msoBorderBottomAlt: 'solid windowtext .5pt',
                              msoBorderRightAlt: 'solid windowtext .5pt',
                              padding: '0cm 5.4pt 0cm 5.4pt',
                              height: '41.3pt',
                            }}
                          >
                            <p className="MsoNormal">
                              <span
                                style={{
                                  fontSize: '10.0pt',
                                  fontFamily: '"Times New Roman",serif',
                                }}
                              ></span>
                            </p>
                          </td>
                          <td
                            width={161}
                            style={{
                              width: '120.45pt',
                              borderTop: 'none',
                              borderLeft: 'none',
                              borderBottom: 'solid windowtext 1.0pt',
                              borderRight: 'solid windowtext 1.0pt',
                              msoBorderTopAlt: 'solid windowtext .5pt',
                              msoBorderLeftAlt: 'solid windowtext .5pt',
                              msoBorderAlt: 'solid windowtext .5pt',
                              padding: '0cm 5.4pt 0cm 5.4pt',
                              height: '41.3pt',
                            }}
                          >
                            <p className="MsoNormal">
                              <span
                                style={{
                                  fontSize: '10.0pt',
                                  fontFamily: '"Times New Roman",serif',
                                }}
                              ></span>
                            </p>
                          </td>
                          <td
                            width={104}
                            style={{
                              width: '78.0pt',
                              borderTop: 'none',
                              borderLeft: 'none',
                              borderBottom: 'solid windowtext 1.0pt',
                              borderRight: 'solid windowtext 1.0pt',
                              msoBorderTopAlt: 'solid windowtext .5pt',
                              msoBorderLeftAlt: 'solid windowtext .5pt',
                              msoBorderAlt: 'solid windowtext .5pt',
                              padding: '0cm 5.4pt 0cm 5.4pt',
                              height: '41.3pt',
                            }}
                          >
                            <p className="MsoNormal">
                              <span
                                style={{
                                  fontSize: '10.0pt',
                                  fontFamily: '"Times New Roman",serif',
                                }}
                              ></span>
                            </p>
                          </td>
                          <td
                            width={111}
                            style={{
                              width: '82.9pt',
                              border: 'none',
                              borderBottom: 'solid windowtext 1.0pt',
                              msoBorderTopAlt: 'solid windowtext .5pt',
                              msoBorderLeftAlt: 'solid windowtext .5pt',
                              msoBorderBottomAlt: 'solid windowtext .5pt',
                              padding: '0cm 5.4pt 0cm 5.4pt',
                              height: '41.3pt',
                            }}
                          >
                            <p className="MsoNormal">
                              <span
                                style={{
                                  fontSize: '10.0pt',
                                  fontFamily: '"Times New Roman",serif',
                                }}
                              ></span>
                            </p>
                          </td>
                        </tr>
                      )}
                      {this.props.currentApplication.achievements.length < 1 && (
                        <tr style={{ msoYftiIrow: 8, height: '27.75pt' }}>
                          <td
                            width={131}
                            style={{
                              width: '98.6pt',
                              borderTop: 'none',
                              borderLeft: 'none',
                              borderBottom: 'solid windowtext 1.0pt',
                              borderRight: 'solid windowtext 1.0pt',
                              msoBorderTopAlt: 'solid windowtext .5pt',
                              msoBorderBottomAlt: 'solid windowtext .5pt',
                              msoBorderRightAlt: 'solid windowtext .5pt',
                              padding: '0cm 5.4pt 0cm 5.4pt',
                              height: '27.75pt',
                            }}
                          >
                            <p className="MsoNormal">
                              <span
                                style={{
                                  fontSize: '10.0pt',
                                  fontFamily: '"Times New Roman",serif',
                                }}
                              ></span>
                            </p>
                          </td>
                          <td
                            width={161}
                            style={{
                              width: '120.45pt',
                              borderTop: 'none',
                              borderLeft: 'none',
                              borderBottom: 'solid windowtext 1.0pt',
                              borderRight: 'solid windowtext 1.0pt',
                              msoBorderTopAlt: 'solid windowtext .5pt',
                              msoBorderLeftAlt: 'solid windowtext .5pt',
                              msoBorderAlt: 'solid windowtext .5pt',
                              padding: '0cm 5.4pt 0cm 5.4pt',
                              height: '27.75pt',
                            }}
                          >
                            <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}>
                              <span
                                style={{
                                  fontSize: '10.0pt',
                                  fontFamily: '"Times New Roman",serif',
                                }}
                              ></span>
                            </p>
                            <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}>
                              <span
                                style={{
                                  fontSize: '10.0pt',
                                  fontFamily: '"Times New Roman",serif',
                                }}
                              ></span>
                            </p>
                          </td>
                          <td
                            width={104}
                            style={{
                              width: '78.0pt',
                              borderTop: 'none',
                              borderLeft: 'none',
                              borderBottom: 'solid windowtext 1.0pt',
                              borderRight: 'solid windowtext 1.0pt',
                              msoBorderTopAlt: 'solid windowtext .5pt',
                              msoBorderLeftAlt: 'solid windowtext .5pt',
                              msoBorderAlt: 'solid windowtext .5pt',
                              padding: '0cm 5.4pt 0cm 5.4pt',
                              height: '27.75pt',
                            }}
                          >
                            <p className="MsoNormal">
                              <span
                                style={{
                                  fontSize: '10.0pt',
                                  fontFamily: '"Times New Roman",serif',
                                }}
                              >
                                ̳
                              </span>
                            </p>
                          </td>
                          <td
                            width={111}
                            style={{
                              width: '82.9pt',
                              border: 'none',
                              borderBottom: 'solid windowtext 1.0pt',
                              msoBorderTopAlt: 'solid windowtext .5pt',
                              msoBorderLeftAlt: 'solid windowtext .5pt',
                              msoBorderBottomAlt: 'solid windowtext .5pt',
                              padding: '0cm 5.4pt 0cm 5.4pt',
                              height: '27.75pt',
                            }}
                          >
                            <p className="MsoNormal">
                              <span
                                style={{
                                  fontSize: '10.0pt',
                                  fontFamily: '"Times New Roman",serif',
                                }}
                              ></span>
                            </p>
                          </td>
                        </tr>
                      )}
                      <tr style={{ msoYftiIrow: 9, height: '27.45pt' }}>
                        <td
                          width={131}
                          style={{
                            width: '98.6pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderBottomAlt: 'solid windowtext .5pt',
                            msoBorderRightAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '27.45pt',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              Суддівська колегія
                            </span>
                          </p>
                        </td>
                        <td
                          width={161}
                          style={{
                            width: '120.45pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderLeftAlt: 'solid windowtext .5pt',
                            msoBorderAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '27.45pt',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              Власне ім'я ПРІЗВИЩЕ
                            </span>
                          </p>
                        </td>
                        <td
                          width={104}
                          style={{
                            width: '78.0pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderLeftAlt: 'solid windowtext .5pt',
                            msoBorderAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '27.45pt',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              Місто, країна
                            </span>
                          </p>
                        </td>
                        <td
                          width={111}
                          style={{
                            width: '82.9pt',
                            border: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderLeftAlt: 'solid windowtext .5pt',
                            msoBorderBottomAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '27.45pt',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              Суддівська категорія
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr style={{ msoYftiIrow: 10, height: '28.55pt' }}>
                        <td
                          width={131}
                          style={{
                            width: '98.6pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderBottomAlt: 'solid windowtext .5pt',
                            msoBorderRightAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '28.55pt',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              Головний суддя
                            </span>
                          </p>
                        </td>
                        <td
                          width={161}
                          style={{
                            width: '120.45pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderLeftAlt: 'solid windowtext .5pt',
                            msoBorderAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '28.55pt',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              {this.props.currentApplication.eventMainJudgeFirstName}{' '}
                              {this.props.currentApplication.eventMainJudgeLastName?.toUpperCase()}
                            </span>
                          </p>
                        </td>
                        <td
                          width={104}
                          style={{
                            width: '78.0pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderLeftAlt: 'solid windowtext .5pt',
                            msoBorderAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '28.55pt',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              {this.props.currentApplication.eventMainJudgeCity}
                            </span>
                          </p>
                        </td>
                        <td
                          width={111}
                          style={{
                            width: '82.9pt',
                            border: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderLeftAlt: 'solid windowtext .5pt',
                            msoBorderBottomAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '28.55pt',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              {this.props.fullList &&
                                this.props.fullList[5] &&
                                this.props.fullList[5].map((elem) => {
                                  if (
                                    elem.id === this.props.currentApplication.eventMainJudgeCategory
                                  )
                                    return <>{elem.title}</>;
                                })}
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr style={{ msoYftiIrow: 11, height: '27.55pt' }}>
                        <td
                          width={131}
                          style={{
                            width: '98.6pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderBottomAlt: 'solid windowtext .5pt',
                            msoBorderRightAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '27.55pt',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              Заступник головного судді
                            </span>
                          </p>
                        </td>
                        <td
                          width={161}
                          style={{
                            width: '120.45pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderLeftAlt: 'solid windowtext .5pt',
                            msoBorderAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '27.55pt',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              {this.props.currentApplication.eventViceMainJudgeFirstName}{' '}
                              {this.props.currentApplication.eventViceMainJudgeLastName?.toUpperCase()}
                            </span>
                          </p>
                        </td>
                        <td
                          width={104}
                          style={{
                            width: '78.0pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderLeftAlt: 'solid windowtext .5pt',
                            msoBorderAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '27.55pt',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              {this.props.currentApplication.eventViceMainJudgeCity}
                            </span>
                          </p>
                        </td>
                        <td
                          width={111}
                          style={{
                            width: '82.9pt',
                            border: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderLeftAlt: 'solid windowtext .5pt',
                            msoBorderBottomAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '27.55pt',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              {this.props.fullList &&
                                this.props.fullList[5] &&
                                this.props.fullList[5].map((elem) => {
                                  if (
                                    elem.id ===
                                    this.props.currentApplication.eventViceMainJudgeCategory
                                  )
                                    return <>{elem.title}</>;
                                })}
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr style={{ msoYftiIrow: 11, height: '27.55pt' }}>
                        <td
                          width={131}
                          style={{
                            width: '98.6pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderBottomAlt: 'solid windowtext .5pt',
                            msoBorderRightAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '27.55pt',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              Суддя
                            </span>
                          </p>
                        </td>
                        <td
                          width={161}
                          style={{
                            width: '120.45pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderLeftAlt: 'solid windowtext .5pt',
                            msoBorderAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '27.55pt',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              {this.props.currentApplication.eventJudgeFirstName}{' '}
                              {this.props.currentApplication.eventJudgeLastName?.toUpperCase()}
                            </span>
                          </p>
                        </td>
                        <td
                          width={104}
                          style={{
                            width: '78.0pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderLeftAlt: 'solid windowtext .5pt',
                            msoBorderAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '27.55pt',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              {this.props.currentApplication.eventJudgeCity}
                            </span>
                          </p>
                        </td>
                        <td
                          width={111}
                          style={{
                            width: '82.9pt',
                            border: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderLeftAlt: 'solid windowtext .5pt',
                            msoBorderBottomAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '27.55pt',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              {this.props.fullList &&
                                this.props.fullList[5] &&
                                this.props.fullList[5].map((elem) => {
                                  if (elem.id === this.props.currentApplication.eventJudgeCategory)
                                    return <>{elem.title}</>;
                                })}
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr style={{ msoYftiIrow: 12, height: '27.25pt' }}>
                        <td
                          width={131}
                          style={{
                            width: '98.6pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderBottomAlt: 'solid windowtext .5pt',
                            msoBorderRightAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '27.25pt',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            ></span>
                          </p>
                        </td>
                        <td
                          width={161}
                          style={{
                            width: '120.45pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderLeftAlt: 'solid windowtext .5pt',
                            msoBorderAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '27.25pt',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            ></span>
                          </p>
                        </td>
                        <td
                          width={104}
                          style={{
                            width: '78.0pt',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderLeftAlt: 'solid windowtext .5pt',
                            msoBorderAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '27.25pt',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            ></span>
                          </p>
                        </td>
                        <td
                          width={111}
                          style={{
                            width: '82.9pt',
                            border: 'none',
                            borderBottom: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderLeftAlt: 'solid windowtext .5pt',
                            msoBorderBottomAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '27.25pt',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            ></span>
                          </p>
                        </td>
                      </tr>
                      <tr
                        style={{
                          msoYftiIrow: 13,
                          msoYftiLastrow: 'yes',
                          height: '48.0pt',
                        }}
                      >
                        <td
                          width={396}
                          colSpan={3}
                          style={{
                            width: '297.05pt',
                            border: 'none',
                            borderRight: 'solid windowtext 1.0pt',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderRightAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '48.0pt',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            >
                              Дата надходження до структурного підрозділу Мінмолодьспорту, до
                              повноважень якого відносяться питання присвоєння спортивних звань
                              ________________________________
                            </span>
                          </p>
                        </td>
                        <td
                          width={111}
                          style={{
                            width: '82.9pt',
                            border: 'none',
                            msoBorderTopAlt: 'solid windowtext .5pt',
                            msoBorderLeftAlt: 'solid windowtext .5pt',
                            padding: '0cm 5.4pt 0cm 5.4pt',
                            height: '48.0pt',
                          }}
                        >
                          <p className="MsoNormal">
                            <span
                              style={{
                                fontSize: '10.0pt',
                                fontFamily: '"Times New Roman",serif',
                              }}
                            ></span>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <p className="MsoNormal">
            <span
              lang="UK"
              style={{
                fontSize: '11.0pt',
                fontFamily: '"Times New Roman",serif',
                msoAnsiLanguage: 'UK',
              }}
            ></span>
          </p>
        </div>
      </>
    );
  }
}

export default PodanNew2;
