import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Avatar from '../../components/Avatar/Avatar';
import { getApplicationItem } from '../../redux/actions/applications';
import { getDocumentsList } from '../../redux/actions/documents';
import { createHistory } from '../../redux/actions/history';
import Form from '../DirectoriesPage/Form/Form';
import Application from './Application/Application';
import ApplyList from './ApplyLists/ApplyList';
import Comments from './Comments/Comments';
import Documents from './Documents/Documents';
import HistoryOfChange from './History/HistoryOfChange';
import {
  selectCurrentOrganisation,
  selectIsAdmin,
  selectIsHeadOrTrustedEmployee,
} from '../../redux/selectors/auth';
import { AppStatus, OrgType, SportType } from '../../constants/const';
import { DigitalSignatureModal } from '../../components/DigitalSignatureModal/DigitalSignatureModal';
import ApplicationService from '../../redux/services/application.service';
import { selectApplicationStatuses } from '../../redux/selectors/dictionaries';
import Assign from './Assign/Assign';
import applicationService from '../../redux/services/application.service';
import certificateService from '../../redux/services/certificate.service';



const CreateApplicationPage = () => {
  if (document.getElementsByClassName('sidebar')[0]) {
    document.getElementsByClassName('sidebar')[0].hidden = true;
  }

  const dispatch = useDispatch();
  const history = useHistory();
  const applicationId = useParams().id;
  const isAdmin = useSelector(selectIsAdmin);

  const [ready, setReady] = useState(false);
  const [statusFull, setStatusFull] = useState({});
  const [kepModalProps, setKepModalProps] = useState({});
  const [alertActive, setAlertActive] = useState(false);
  const [signatureError, setSignatureError] = useState();
  const [form, setForm] = useState({ id: applicationId });
  const [comment, setComment] = useState({});
  const [error, setError] = useState({});
  const [formActive, setFormActive] = useState(false);
  const [declineCer, setDeclineCer] = useState(false);
  const [decline, setDecline] = useState(false);
  const [declineReg, setDeclineReg] = useState(false);
  const [department, setDepartment] = useState(false);
  const [depDecline, setDepDecline] = useState(false);
  const [depCommission, setDepCommission] = useState(false);
  const [trainerApply, setTrainerApply] = useState(false);
  const [trainerHeadApply, setTrainerHeadApply] = useState(false);
  const [office, setOffice] = useState(false);
  const [departmentHeadRet, setDepartmentHeadRet] = useState(false);
  const [departmentHeadDec, setDepartmentHeadDec] = useState(false);
  const [departmentHeadCom, setDepartmentHeadCom] = useState(false);
  const [organisationHeadRet, setOrganisationHeadRet] = useState(false);
  const [organisationHeadDec, setOrganisationHeadDec] = useState(false);
  const [organisationHeadCom, setOrganisationHeadCom] = useState(false);
  const [awardCom, setAwardCom] = useState(false);
  const [awardRet, setAwardRet] = useState(false);
  const [awardDec, setAwardDec] = useState(false);
  const [incoCom, setIncoCom] = useState(false);
  const [invoDecline, setInvoDecline] = useState(false);
  const [invoHeadRet, setInvoHeadRet] = useState(false);
  const [invoSelect, setInvoSelect] = useState(false);
  const [invoFederation, setInvoFederation] = useState(false);
  const [invoDepFederation, setInvoDepFederation] = useState(false);
  const [invoRetFed, setInvoRetFed] = useState(false);
  const [doc, setDoc] = useState({});
  const [activeTab, setActiveTab] = useState('application');
  const [orderForm, setOrderForm] = useState({});
  const [printCer, setPrintCer] = useState(false);
  const [selectDep, setSelectDep] = useState([]);

  const documents = useSelector((state) => state.documents);
  const currentApplication = useSelector((state) => state.applications.currentApplication);
  const fullList = useSelector((state) => state.fullList);
  const applicationStatuses = useSelector(selectApplicationStatuses);
  const currentOrganisation = useSelector(selectCurrentOrganisation);
  const isHeadOrTrustedEmployee = useSelector(selectIsHeadOrTrustedEmployee);
  const { list: coverList } = useSelector((state) => state.cover);

  const enabledKep =true;

  const backClick = () => {
    history.push('/applications');
  };

  const validateApplication = () => {
    fetchApplication();
    const isZTU =
      currentApplication?.athleteCategoryTitle ===
      fullList[2][1].title + '/' + fullList[2][1].description;

    let errorObj = {};
    // check documents with type 3,11,5
    if (!doc[3] || !doc[11] || !doc[5]) {
      errorObj.doc = true;
    }
    // check documents with type 2,6,7,9,14,10 for category fullList[2][1].title+'/'+fullList[2][1].description
    // if (isZTU && (!doc[6] || !doc[7] || !doc[9] || !doc[8] || !doc[14] || !doc[10])) {
      if (isZTU && (!doc[6] || !doc[7] || !doc[9] || !doc[8] || !doc[14] ||!doc[18])) {
      errorObj.doc2 = true;
    }
    // comment what is it
    if (
      (!currentApplication.trainerCategoryFirst && !isZTU) ||
      (!currentApplication.trainerFirstNameFirst && !isZTU) ||
      (!currentApplication.trainerLastNameFirst && !isZTU) ||
      (!currentApplication.trainerSecondNameFirst && !isZTU) ||
      (!currentApplication.sex ||
        !currentApplication.athleteFirstName ||
        !currentApplication.athleteLastName ||
        !currentApplication.athleteLastNameDative ||
        !currentApplication.athleteFirstNameDative ||
        !currentApplication.birthday ||
        !currentApplication.address ||
        !currentApplication.workPlace ||
        !currentApplication.education)||
      (currentApplication.achievements && currentApplication.achievements.length === 0)
    ) {
      errorObj.input = true;
    }

    setError({ ...errorObj });
  };

  const isValidForSubmission = () => {
    const isZTU =
      fullList[2] &&
      fullList[2][1] &&
      currentApplication.athleteCategoryTitle ===
        fullList[2][1].title + '/' + fullList[2][1].description;

    return !(
      !currentApplication ||
      !fullList ||
      !doc[3] ||
      !doc[11] ||
      !doc[5] ||
      // (isZTU && (!doc[6] || !doc[7] || !doc[9] || !doc[8] || !doc[14] || !doc[10])) ||
      (isZTU && (!doc[6] || !doc[7] || !doc[9] || !doc[8] || !doc[14])) ||
      currentApplication.sportCategoryType === null ||
      !currentApplication.sportTitle ||
      !currentApplication.sex ||
      !currentApplication.athleteFirstName ||
      !currentApplication.athleteLastName ||
      !currentApplication.athleteLastNameDative ||
      !currentApplication.athleteFirstNameDative ||
      !currentApplication.birthday ||
      !currentApplication.address ||
      !currentApplication.workPlace ||
      !currentApplication.education ||
      (!currentApplication.trainerCategoryFirst && !isZTU) ||
      (!currentApplication.trainerSexFirst && !isZTU) ||
      (!currentApplication.trainerFirstNameFirst && !isZTU) ||
      (!currentApplication.trainerLastNameFirst && !isZTU) ||
      (!currentApplication.trainerSecondNameFirst && !isZTU) ||
      (currentApplication.achievements && currentApplication.achievements.length === 0) ||
      !currentApplication.sportTitle ||
      currentApplication.sportTitle.length === 0
    );
  };

  const changeApplicationStatus = (data) => {
    data.prevStatus=currentApplication.status;
    console.log('changeApplicationStatus')
    console.log(data)
    dispatch(createHistory(data))
      .then((response) => {
        localStorage.removeItem('DocumentSend')
        if (response.error) {
          if (typeof response.error === 'string') {
            setSignatureError(response.error);
          } else {
            setSignatureError('Сталася технічна помилка. Спробуйте ще раз');
          }
        } else {
          fetchApplication();
          setActiveTab('application');
          setFormActive(false);
          setDecline(false);
          setDepartmentHeadCom(false);
          setDepartmentHeadRet(false);
          setDepartmentHeadDec(false);
          setDepCommission(false);
          setDepDecline(false);
          setDepartment(false);
          setIncoCom(false);
          setInvoRetFed(false);
          setInvoHeadRet(false);
          setInvoFederation(false);
          setInvoDepFederation(false);
          setInvoDecline(false);
          setOrganisationHeadCom(false);
          setOrganisationHeadRet(false);
          setOrganisationHeadDec(false);
          setAwardCom(false);
          setAwardRet(false);
          setAwardDec(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const submitApplication = (params = {}) => {
    changeApplicationStatus({
      id: applicationId,
      status: AppStatus.RegionSportReview,
      ...params,
    });
  };

  const submitDigitalApplication = () => {
    ApplicationService.generateDigitalApplication(applicationId).then((data) => {
      if (data && data.file) {
        console.log('submitDigitalApplication')
        console.log(data);
        setKepModalProps({
          data: data.file,
          previousSign: null,
          visible: true,
          onSign: (params) => submitApplication(params),
        });
      }
    });
  };

  const callWithDigitalSignature = (onSign) => {
    if (currentApplication.isDigital) {
      ApplicationService.getDigitalApplication(applicationId).then((data) => {
        if (data && data.file) {
          console.log(data)
          console.log('data')
          setKepModalProps({
            data: data.file,
            // previousSign: data.signature,
            previousSign: null,
            visible: true,
            onSign,
          });
        }
      });
    } else {
      onSign({});
    }
  };

  const callWithDigitalSignatureWithFileType = (onSign,object) => {
    if (currentApplication.isDigital) {
      let body= object;
      ApplicationService.getDigitalApplicationFileType(body).then((data) => {
        if (data && data.file) {
          setKepModalProps({
            data: data.file,
            // previousSign: data.signature,
            previousSign: null,
            visible: true,
            onSign,
            urlPrev:data.url
          });
        }
      });
    } else {
      onSign({},object);
    }
  };

  const createSignatureForFile=(info,object)=>{
    ApplicationService.setDigitalApplicationSignature(info).then(res=>{
      if(object.type=='19'||object.type=='20'){
        changeApplicationStatus(object);
      }else{
        localStorage.setItem('DocumentSend',JSON.stringify(object));
        window.location.reload();
      }
    })
  }


  useEffect(() => {
    if(ready && currentApplication && (currentApplication.status=='13'||currentApplication.status=='9')){
      if(localStorage.getItem('DocumentSend')){
        let obj = JSON.parse(localStorage.getItem('DocumentSend'));
        callWithDigitalSignature((params) =>
        changeApplicationStatus({
          ...obj,
          ...params,
        }),
      )
      }
    }else if(ready && currentApplication && currentApplication.status=='15'){
      if(localStorage.getItem('DocumentSend')){
        let obj = JSON.parse(localStorage.getItem('DocumentSend'));
        changeApplicationStatus({
          ...obj
        })
      }
    }
    
  }, [currentApplication,ready]);


  const organisationHeadCommission = () => {
    let object= {
      applicationId:applicationId,
      id: applicationId,
      status: AppStatus.RewardSportCommission,
      comment:comment.comment,
      isCommissionRequested: true,
      isApproved:0,
      type:19
    }
    callWithDigitalSignatureWithFileType((data) =>{
      createSignatureForFile({
        id: applicationId,
        type:20,
        ...data,
      },object)
    }
    ,object)
  };

  const organisationHeadDecline = () => {
    let object= {
      applicationId:applicationId,
      comment:comment.comment,
      isApproved:0,
      id: applicationId,
      status: AppStatus.Rejected,
      type:21
    }
    callWithDigitalSignatureWithFileType((data) =>{
      createSignatureForFile({
        id: applicationId,
        type:22,
        ...data,
      },object)
    }
    ,object);
  };

  const organisationHeadApprove = () => {
    let object= {
      applicationId:applicationId,
      id: applicationId,
      isApproved:1,
      id: applicationId,
      status: AppStatus.Accepted,
      type:21,
      isConfirmed: true,
    }
    callWithDigitalSignatureWithFileType((data) =>{
      createSignatureForFile({
        id: applicationId,
        type:22,
        ...data,
      },object)
    }
    ,object);
  };


  const awardCommission = () => {
    let object= {
      applicationId:applicationId,
      id: applicationId,
      commissionConclusion:1,
      fileComment: comment.scan,
      comment: comment.comment,
    }
    if(currentApplication.sportCategoryType =='1' || currentApplication.sportCategoryType=='0'){
      object.status= AppStatus.DepartmentHeadEmployeeReview
    }else{
      object.status= AppStatus.DepartmentInvasportHeadEmployeeReview
    }
    changeApplicationStatus(object);
    
  };

  const awardDecline = () => {
    let object= {
      applicationId:applicationId,
      id: applicationId,
      commissionConclusion:0,
      fileComment: comment.scan,
      comment: comment.comment,
    }
    if(currentApplication.sportCategoryType =='1' || currentApplication.sportCategoryType=='0'){
      object.status= AppStatus.DepartmentHeadEmployeeReview
    }else{
      object.status= AppStatus.DepartmentInvasportHeadEmployeeReview
    }
    changeApplicationStatus(object);
  };


  const regionSportAcceptClick = () => {
    let status= '';
    if(currentApplication.sportCategoryType =='0'){
      status = AppStatus.TrainerReview;
    }else if (currentApplication.sportCategoryType =='1'){
      status = AppStatus.TrainerReview;
    }else if (currentApplication.sportCategoryType =='2'){
      status = AppStatus.DisabilityFederationReview;
    }
    callWithDigitalSignature((params) => {
      changeApplicationStatus({
        id: applicationId,
        status: status,
        ...params,
      });
    });
  };

  const applicationFixingClick = () => {
    setDepCommission(false);
    setDepDecline(false);
    setInvoDecline(false);
    setInvoRetFed(false);
    setAwardCom(false);
    setAwardDec(false);
    setAwardRet(false);
    setDeclineReg(false);
    setDecline(true);
    setFormActive(true);
  };
  const regionSportDeclineClick= ()=>{
    setDecline(false);
    setFormActive(true);
    setDeclineReg(true);
  }
  const regionSportDeclinApprove =()=>{
    callWithDigitalSignature((params) =>
      changeApplicationStatus({
        id: applicationId,
        status: AppStatus.Rejected,
        comment: comment.comment,
        ...params,
      }),
    );
  }

  const applicationFixingReg=()=>{
    changeApplicationStatus({
      id: applicationId,
      status: AppStatus.SubmissionSubjectFixing,
      comment: comment.comment,
    });
  }

  const printDublicateCert =()=>{
    setPrintCer(true);
    setDeclineCer(false);
    setFormActive(true);
  }

  const declineGettedCertificateClick =()=>{
    setDeclineCer(true);
    setPrintCer(false);
    setFormActive(true);
  }


  const declineGettedCertificate =()=>{
    let data={
      applicationId:applicationId,
      isDecline:1,
      urlDecline:orderForm.urlDecline
    }
      applicationService.certificateFilter(data).then(res=>{
        setFormActive(false);
        fetchApplication();
      })
  }

  const applicationFixing = () => {
    callWithDigitalSignature((params) =>
      changeApplicationStatus({
        id: applicationId,
        status: AppStatus.SubmissionSubjectFixing,
        comment: comment.comment,
        ...params,
      }),
    );
  };

  const regionSportPrintClick = () => {
    history.push(`/print/${applicationId}/list`);
  };

  const officeAcceptClick = () => {
    setOffice(true);
    setFormActive(true);
  };

  const officeAcceptDigital = () => {
    callWithDigitalSignature((params) => {
      let data = {};
      if (
        currentApplication &&
        currentApplication.officeCode &&
        currentApplication.officeCode.length > 0
      ) {
        if (
          currentApplication &&
          (currentApplication.sportCategoryType === SportType.Olympic ||
            currentApplication.sportCategoryType === SportType.NonOlympic) &&
          currentApplication.athleteLastName.length > 0
        ) {
          data = {
            id: applicationId,
            status: AppStatus.DepartmentReview,
            officeCode: currentApplication.officeCode,
          };
        } else if (
          currentApplication &&
          currentApplication.sportCategoryType &&
          currentApplication.sportCategoryType > 1
        ) {
          data = {
            id: applicationId,
            status: AppStatus.DepartmentInvasportReview,
            officeCode: currentApplication.officeCode,
          };
        }
      } else if (
        currentApplication &&
        (currentApplication.sportCategoryType === SportType.Olympic ||
          currentApplication.sportCategoryType === SportType.NonOlympic) &&
        currentApplication.athleteLastName.length > 0
      ) {
        data = {
          id: applicationId,
          status: AppStatus.DepartmentReview,
          officeCode: comment.comment,
        };
      } else if (
        currentApplication &&
        currentApplication.sportCategoryType &&
        currentApplication.sportCategoryType > 1
      ) {
        data = {
          id: applicationId,
          status: AppStatus.DepartmentInvasportReview,
          officeCode: comment.comment,
        };
      }

      changeApplicationStatus({
        ...data,
        ...params,
      });
    });
  };

  const assignDepartmentClick = () => {
    setFormActive(true);
    setDepartment(true);
  };

  const assignDepartment = () => {
    callWithDigitalSignature((params) =>
      changeApplicationStatus({
        id: applicationId,
        status: AppStatus.DepartmentSectionHeadEmployeeReview,
        departmentId: comment.departmentId,
        federationId: comment.departmentId,
        ...params,
      }),
    );
  };

  const trainerAcceptClick = () => {
    setTrainerApply(true);
    setFormActive(true);
  };

  const trainerAccept = () => {
    // if(currentApplication.isTrainerReview && isHeadOrTrustedEmployee){
      callWithDigitalSignature((params) =>
      changeApplicationStatus({
        id: applicationId,
        status: AppStatus.DepartmentSectionReview,
        comment: comment.comment,
        ...params,
      }),
    );
    // }
  };
  const trainerHeadAcceptClick = () => {
    setTrainerHeadApply(true);
    setFormActive(true);
  };

  const headTrainerClick = ()=>{
        let data={
          id: applicationId,
          comment: comment.comment,
          isTrainerReview:true,
        }
        applicationService.setTrainerReview(data).then(res=>{
          setTrainerHeadApply(false);
          setFormActive(false);
          fetchApplication();

        })
  }

  const assignEmployeeClick = () => {
    history.push(`/applications/${applicationId}/${currentOrganisation.id}`);
  };

  const assignTrainerClick = () => {
    history.push(`/applications/${applicationId}/${currentApplication.sportCategoryType}`);
  };

  const trainerFixingClick = () => {
    callWithDigitalSignature((params) =>
      changeApplicationStatus({
        id: applicationId,
        status: AppStatus.TrainerFixing,
        ...params,
      }),
    );
  };

  const departmentAccept = () => {
    callWithDigitalSignature((params) =>
      changeApplicationStatus({
        id: applicationId,
        status: AppStatus.DepartmentSectionHeadEmployeeReview,
        isConfirmed: true,
        ...params,
      }),
    );
  };

  const departmentHeadAccept = () => {
    callWithDigitalSignature((params) =>
      changeApplicationStatus({
        id: applicationId,
        status: AppStatus.DepartmentHeadEmployeeReview,
        isConfirmed: true,
        ...params,
      }),
    );
  };

  const departmentHeadReturnClick = () => {
    setDepartmentHeadDec(false);
    setDepartmentHeadCom(false);
    setDepartmentHeadRet(true);
    setFormActive(true);
  };

  const departmentHeadReturn = () => {
    callWithDigitalSignature((params) =>
      changeApplicationStatus({
        id: applicationId,
        status: AppStatus.DepartmentSectionReview,
        comment: comment.comment,
        ...params,
      }),
    );
  };

  const departmentHeadDeclineClick = () => {
    setDepartmentHeadDec(true);
    setDepartmentHeadCom(false);
    setDepartmentHeadRet(false);
    setFormActive(true);
  };

  const departmentHeadDecline = () => {
    callWithDigitalSignature((params) =>
      changeApplicationStatus({
        id: applicationId,
        status: AppStatus.DepartmentHeadEmployeeReview,
        isConfirmed: false,
        comment: comment.comment,
        ...params,
      }),
    );
  };

  const setPrintSave = () => {
    let arr = [[], [], []];
    let data = {
      applications: [applicationId],
      data: {
        status: 21,
      },
    };
    ApplicationService.updateMultipleApplications(data).then((res) => {
      console.log(orderForm);
      let data2 = {
        applications: [applicationId],
        data: {
          signer2Name: '',
          signer2Position: '',
        },
      };
      fullList[8].forEach((elem) => {
        if (elem.id == orderForm['signer2Name'+currentApplication.athleteCategoryTitle]) {
          data2.data.signer2Name = elem.firstName[0] + '.' + elem.lastName.toUpperCase();
          data2.data.signer2Position = elem.position;
        }
      });
        certificateService.updateMultipleCertificate(data2).then(res=>{
          let data={
            applicationId:applicationId,
            hasCopy:1
          }
            applicationService.certificateFilter(data).then(res=>{
              history.push('/print/staticlist/res');
            })
        })
    });
  };

  const departmentHeadCommissionClick = () => {
    setDepartmentHeadDec(false);
    setDepartmentHeadCom(true);
    setDepartmentHeadRet(false);
    setFormActive(true);
  };

  const departmentHeadCommission = () => {
    callWithDigitalSignature((params) =>
      changeApplicationStatus({
        id: applicationId,
        status: AppStatus.DepartmentHeadEmployeeReview,
        isCommissionRequested: true,
        comment: comment.comment,
        ...params,
      }),
    );
  };

  const departmentDecline = () => {
    callWithDigitalSignature((params) =>
      changeApplicationStatus({
        id: applicationId,
        status: AppStatus.DepartmentSectionHeadEmployeeReview,
        isConfirmed: false,
        comment: comment.comment,
        ...params,
      }),
    );
  };

  const departmentDeclineClick = () => {
    setDepCommission(false);
    setFormActive(true);
    setDepDecline(true);
  };

  const departmentCommissionClick = () => {
    setDepDecline(false);
    setFormActive(true);
    setDepCommission(true);
  };

  const departmentCommission = () => {
    callWithDigitalSignature((params) =>
      changeApplicationStatus({
        id: applicationId,
        status: AppStatus.DepartmentSectionHeadEmployeeReview,
        isCommissionRequested: true,
        comment: comment.comment,
        ...params,
      }),
    );
  };

  const invasportDeclineClick = () => {
    setInvoSelect(false);
    setDecline(false);
    setInvoRetFed(false);
    setIncoCom(false);

    setInvoDecline(true);
    setFormActive(true);
  };

  const invoSportRetFeder = () => {
    callWithDigitalSignature((params) =>
      changeApplicationStatus({
        id: applicationId,
        status: AppStatus.DisabilityFederationFixing,
        departmentId: currentApplication.federationId,
        federationId: currentApplication.federationId,
        comment: comment.comment,
        ...params,
      }),
    );
  };

  const invoSportHeadRet = () => {
    callWithDigitalSignature((params) =>
      changeApplicationStatus({
        id: applicationId,
        status: AppStatus.DepartmentInvasportReview,
        comment: comment.comment,
        ...params,
      }),
    );
  };

  const invasportCommissionClick = () => {
    setInvoDecline(false);
    setInvoRetFed(false);
    setIncoCom(true);
    setFormActive(true);
  };

  const invasportCommission = () => {
    callWithDigitalSignature((params) =>
      changeApplicationStatus({
        id: applicationId,
        status: AppStatus.DepartmentInvasportHeadEmployeeReview,
        isCommissionRequested: true,
        ...params,
      }),
    );
  };

  const invasportSelectDepartment = () => {
    callWithDigitalSignature((params) =>
      changeApplicationStatus({
        id: applicationId,
        status: AppStatus.DisabilityFederationReview,
        departmentId: comment.departmentId,
        federationId: comment.departmentId,
        ...params,
      }),
    );
  };

  const invasportAcceptClick = () => {
    setInvoFederation(true);
    setFormActive(true);
  };

  const disabilityAcceptClick = () => {
    setInvoDepFederation(true);
    setFormActive(true);
  };

  const disabilityFederationApprove = ()=>{
    callWithDigitalSignature((params) =>
      changeApplicationStatus({
        id: applicationId,
        status: AppStatus.DepartmentInvasportReview,
        isConfirmed: true,
        comment: comment.comment,
        ...params,
      }),
    );
  }

  const invoFeder = () => {
    callWithDigitalSignature((params) =>
      changeApplicationStatus({
        id: applicationId,
        status: AppStatus.DepartmentInvasportHeadEmployeeReview,
        isConfirmed: true,
        comment: comment.comment,
        ...params,
      }),
    );
  };

  const invoSportDecline = () => {
    callWithDigitalSignature((params) =>
      changeApplicationStatus({
        id: applicationId,
        status: AppStatus.DepartmentInvasportHeadEmployeeReview,
        isConfirmed: false,
        comment: comment.comment,
        ...params,
      }),
    );
  };

  const organisationHeadCommissionClick = () => {
    setOrganisationHeadDec(false);
    setOrganisationHeadRet(false);
    setInvoHeadRet(false);
    setOrganisationHeadCom(true);
    setFormActive(true);
  };



  const organisationHeadReturnClick = () => {
    setOrganisationHeadDec(false);
    setOrganisationHeadCom(false);
    setOrganisationHeadRet(true);
    setFormActive(true);
  };

  const organisationHeadReturn = () => {
    callWithDigitalSignature((params) =>
      changeApplicationStatus({
        id: applicationId,
        status: AppStatus.DepartmentSectionHeadEmployeeReview,
        comment: comment.comment,
        ...params,
      }),
    );
  };

  const organisationHeadDeclineClick = () => {
    setOrganisationHeadCom(false);
    setOrganisationHeadRet(false);
    setInvoHeadRet(false);
    setOrganisationHeadDec(true);
    setFormActive(true);
  };







  const awardReturnClick = () => {
    setAwardCom(false);
    setAwardDec(false);
    setAwardRet(true);
    setFormActive(true);
  };

  const awardReturn = () => {
    callWithDigitalSignature((params) =>
      changeApplicationStatus({
        id: applicationId,
        status: AppStatus.DepartmentReview,
        comment: comment.comment,
        ...params,
      }),
    );
  };

  const awardDeclineClick = () => {
    setAwardCom(false);
    setAwardRet(false);
    setAwardDec(true);
    setFormActive(true);
  };

  const awardCommissionClick = () => {
    setAwardCom(true);
    setAwardRet(false);
    setAwardDec(false);
    setFormActive(true);
  };


  const onSelectStatus = (event) => {
    callWithDigitalSignature((params) =>
      changeApplicationStatus({
        id: applicationId,
        status: event.target.selectedOptions[0].value,
        ...params,
      }),
    );
  };

  const awardAccept = () => {
    callWithDigitalSignature((params) =>
      changeApplicationStatus({
        id: applicationId,
        status: AppStatus.Accepted,
        comment: comment.comment,
        ...params,
      }),
    );
  };

  const duplicateClick = () => {
    ApplicationService.duplicateApplication(applicationId)
      .then((res) => {
        history.push('/applications');
        history.push(`/applications/${res.id}`);
        fetchApplication(res.id);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteClick = (e) => {
    setAlertActive(true);
  };

  const deleteApplication = async () => {
    await ApplicationService.deleteApplication(applicationId)
      .then((res) => {
        history.push('/applications');
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchApplication = useCallback(async () => {
    await dispatch(getApplicationItem(applicationId))
      .then((response) => {
        setForm({ ...form, ...response });
        let dep=[
          {
            name: applicationId,
            id:applicationId,
            department: response.athleteCategoryTitle
          }
        ]
        setSelectDep(dep);
        sessionStorage.setItem('selectDep', JSON.stringify(dep));
        fetchDocuments();
      })
      .catch((e) => {
        console.log(e);
      });
  }, [form, currentApplication]);

  const fetchDocuments = useCallback(async () => {
    await dispatch(getDocumentsList(applicationId))
      .then((documents) => {
        setDoc([]);
        documents
          .sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1))
          .forEach((element) => {
            setDoc((doc) => ({ ...doc, [element.type]: element }));
          });
        setReady(true);
      })
      .catch((e) => {
        console.log(e);
        setReady(true);
      });
  }, [doc, documents]);

  useEffect(() => {
    if (!ready) {
      fetchApplication();
    }
  }, []);

  if (!ready || !currentOrganisation || !currentApplication) {
    return <>Завантаження...</>;
  }

  return (
    <>
      <div className="main application-page">
        <h3 className="public-sub-header">Публічний реєстр присвоєння спортивних звань</h3>
        <div className="auth-menu">
          <Avatar />
        </div>
        <div className="public-header">
          {!form.athleteLastName &&
            form.athleteCategoryTitle &&
            !form.athleteFirstName &&
            !form.athleteSecondName && <h1>Нова Заявка</h1>}
          {form.athleteLastName &&
            form.athleteCategoryTitle &&
            form.athleteFirstName &&
            form.athleteSecondName && (
              <h1>
                {form.athleteLastName} {form.athleteFirstName} {form.athleteSecondName} (
                {form.athleteCategoryTitle})
              </h1>
            )}
          <h2>
            {currentApplication.organisation?.shortName}{' '}
            {currentApplication.datePublished
              ? new Date(currentApplication.datePublished).toLocaleString('uk-UA', {
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric',
                })
              : ''}
          </h2>
          <label>
            {form.officeCode && (
              <>
                Номер заявки: {form.officeCode}
                <br />
              </>
            )}{' '}
            Статус заявки:{' '}
            {currentApplication.isDecline? 'Звання скасоване':applicationStatuses.map((appStatus) => {
              if (appStatus.status === currentApplication.status) {
                if (
                  currentApplication.status === AppStatus.DepartmentSectionHeadEmployeeReview ||
                  currentApplication.status === AppStatus.DepartmentHeadEmployeeReview ||
                  currentApplication.status === AppStatus.DepartmentInvasportHeadEmployeeReview
                ) {
                  return (
                    <React.Fragment key={appStatus.status}>
                      {appStatus.title}
                      {currentApplication.commissionConclusion=='1' && <span style={{color:'green'}}> | Висновок комісії позитивний</span>}
                      {currentApplication.commissionConclusion=='0' && <span style={{color:'red'}}> | Висновок комісії негативний</span>}
                      {currentApplication.isAcceptedBySectionSpecialist && ' | Прийнято спеціалістом'}
                      {(currentApplication.isAcceptedBySectionSpecialist === false && !currentApplication.isOnComissionBySectionSpecialist) &&
                        ' | Відхилено спеціалістом'}
                      {currentApplication.isOnComissionBySectionSpecialist && 
                        ' | Відправлено на комісію спеціалістом'}
                      {currentApplication.isAcceptedBySectionHead && ' | Прийнято керівником відділу'}
                      {currentApplication.isAcceptedBySectionHead === false &&
                        ' | Відхилено керівником відділу'}
                      {currentApplication.isOnComissionBySectionHead &&
                        ' | Відправлено на комісію керівником відділу'}
                    </React.Fragment>
                  );
                } else if (currentApplication.status === AppStatus.RewardSportCommission) {
                  return (
                    <span key={appStatus.status} style={{ color: 'red' }}>
                      {appStatus.title}
                    </span>
                  );
                } else {
                  return appStatus.title;
                }
              }
            })}{' '}
          </label>
        </div>

        <div className="create-app btn-tab">
          <button className="icon-text-btn-secondary back" onClick={backClick}>
            Назад
          </button>
          {(currentApplication.status === AppStatus.SubmissionSubjectFixing ||
            currentApplication.status === AppStatus.Draft) &&
            currentOrganisation.type === OrgType.SubmissionSubject && (
              <>
                {/* <button
                  disabled={!isValidForSubmission()}
                  className="icon-text-btn-primary btn-primary"
                  onClick={() => submitApplication()}
                >
                  Подати&nbsp;заявку
                </button> */}
                {enabledKep && (
                  <button
                    disabled={!isValidForSubmission()}
                    className="icon-text-btn-primary btn-primary"
                    onClick={submitDigitalApplication}
                    style={{ minWidth: '205px' }}
                  >
                    Подати&nbsp;заявку&nbsp;з&nbsp;КЕП
                  </button>
                )}
                <button
                  className="checkButton"
                  onClick={validateApplication}
                  disabled={!fullList[1] || !fullList[1][0]}
                  title="Перевірити правильність заповнення заявки"
                >
                  ?
                </button>
                <button className="icon-text-btn-secondary duplicate" onClick={duplicateClick}>
                  Дублювати
                </button>
                {currentApplication.status === AppStatus.Draft && (
                  <button className="icon-text-btn-secondary garbage" onClick={deleteClick}>
                    Видалити
                  </button>
                )}
              </>
            )}

          {currentApplication.status === AppStatus.RegionSportReview &&
            currentOrganisation.type === OrgType.RegionSport && (
              <>
                <button
                  className="icon-text-btn-primary btn-primary"
                  onClick={regionSportAcceptClick}
                >
                  Узгодити
                </button>
                <button className="icon-text-btn-secondary" onClick={applicationFixingClick}>
                  <i className="icon return-icon"></i> Повернути&nbsp;заявку&nbsp;на&nbsp;доопрацювання
                </button>
                <button className="icon-text-btn-secondary" onClick={regionSportDeclineClick}>
                  <i className="icon dismiss-icon"></i>Відхилити&nbsp;заявку
                </button>

                {/* <button className="icon-text-btn-secondary print" onClick={regionSportPrintClick}>
                  Роздрукувати&nbsp;шаблон&nbsp;листа
                </button> */}
              </>
            )}
          {currentApplication.status === AppStatus.MinYouthOfficeReview &&
            currentOrganisation.type === OrgType.MinYouthOffice && (
              <>
                {currentApplication.officeCode && currentApplication.officeCode.length ? (
                  <button
                    className="icon-text-btn-primary btn-primary"
                    onClick={officeAcceptDigital}
                  >
                    Відправити
                  </button>
                ) : (
                  <button className="icon-text-btn-primary btn-primary" onClick={officeAcceptClick}>
                    Зареєструвати заявку
                  </button>
                )}
              </>
            )}
          {/* {currentApplication.status === AppStatus.DepartmentReview &&
            (currentOrganisation.type === OrgType.DepartmentOlympic ||
              currentOrganisation.type === OrgType.DepartmentNonOlympic) && (
              <>
                <button
                  className="icon-text-btn-primary btn-primary"
                  onClick={assignDepartmentClick}
                >
                  Відправити до відділу
                </button>
              </>
            )} */}

          {currentApplication.status === AppStatus.DepartmentSectionReview &&
            currentOrganisation.type === OrgType.DepartmentSection && (
              <>
                {currentApplication.trainerId && (
                  <button className="icon-text-btn-primary btn-primary" onClick={departmentAccept}>
                    Узгодити
                  </button>
                )}
                <button className="icon-text-btn-secondary" onClick={departmentDeclineClick}>
                  <i className="icon dismiss-icon"></i>Відхилити&nbsp;заявку
                </button>
                {currentApplication.trainerId && (
                  <button className="icon-text-btn-secondary" onClick={departmentCommissionClick}>
                    <i className="icon return-icon"></i>  На&nbsp;комісію
                  </button>
                )}
              </>
            )}

          {(currentApplication.status === AppStatus.TrainerReview ||
            currentApplication.status === AppStatus.TrainerFixing) &&
            (currentOrganisation.type === OrgType.TrainersFullTime ||
              currentOrganisation.type === OrgType.TrainersCurators)&&  (
              <>
                <button className="icon-text-btn-primary btn-primary" onClick={trainerAcceptClick}>
                 Результат&nbsp;опрацювання
                </button>
              </>
          )}
          {/* {(currentApplication.status === AppStatus.TrainerReview ||
            currentApplication.status === AppStatus.TrainerFixing) &&
            (currentOrganisation.type === OrgType.TrainersFullTime ||
              currentOrganisation.type === OrgType.TrainersCurators)&& (
              <>
                <button className="icon-text-btn-primary btn-primary" onClick={trainerAcceptClick}>
                 Результат&nbsp;опрацювання
                </button>
              </>
          )} */}

          {currentApplication.status === AppStatus.DepartmentSectionReview &&
            currentOrganisation.type === OrgType.MinYouthOffice && !currentApplication.isTrainerReview && (
              <>
                <button className="icon-text-btn-primary btn-primary" onClick={ trainerHeadAcceptClick}>
                 Результат&nbsp;опрацювання
                </button>
              </>
          )}
          {currentApplication.status === AppStatus.DepartmentSectionHeadEmployeeReview &&
            currentOrganisation.type === OrgType.DepartmentSection &&
            isHeadOrTrustedEmployee && (
              <>
                {currentApplication.employeeId && (
                  <>
                    <button
                      className="icon-text-btn-primary btn-primary"
                      onClick={departmentHeadAccept}
                    >
                      Узгодити
                    </button>
                    <button
                      className="icon-text-btn-secondary"
                      onClick={departmentHeadDeclineClick}
                    >
                      <i className="icon dismiss-icon"></i>Відхилити&nbsp;заявку
                    </button>
                    <button
                      className="icon-text-btn-secondary"
                      onClick={departmentHeadCommissionClick}
                    >
                      На&nbsp;комісію
                    </button>
                  </>
                )}
                {/* {!currentApplication.employeeId && (
                  <button
                    className="icon-text-btn-primary btn-primary"
                    style={{ minWidth: '250px' }}
                    onClick={assignEmployeeClick}
                  >
                    Відправити&nbsp;до&nbsp;спеціаліста
                  </button>
                )} */}
              </>
            )}

          {currentOrganisation.type === OrgType.DepartmentInvasport &&
            currentApplication.status === AppStatus.DepartmentInvasportReview &&
            isHeadOrTrustedEmployee && (
              <>
                {/* {!currentApplication.employeeId && (
                  <button
                    className="icon-text-btn-primary btn-primary"
                    onClick={assignTrainerClick}
                    style={{ maxWidth: '250px' }}
                  >
                    Відправити&nbsp;до&nbsp;спеціаліста
                  </button>
                )} */}
                {currentApplication.employeeId && (
                  <>
                    <button
                      className="icon-text-btn-primary btn-primary"
                      onClick={organisationHeadApprove}
                    >
                      Узгодити
                    </button>
                    <button
                      className="icon-text-btn-secondary"
                      onClick={organisationHeadCommissionClick}
                    >
                      На&nbsp;комісію
                    </button>
                    <button
                      className="icon-text-btn-secondary"
                      onClick={organisationHeadDeclineClick}
                    >
                      <i className="icon dismiss-icon"></i>Відхилити&nbsp;заявку
                    </button>
                  </>
                )}
              </>
            )}
           {currentOrganisation.type === OrgType.UkrDisabilityFederation  &&
            currentApplication.status === AppStatus.DisabilityFederationReview &&

                <button className="icon-text-btn-primary btn-primary" onClick={disabilityAcceptClick}>
                  Результат&nbsp;опрацювання
                </button>}
          {currentOrganisation.type === OrgType.DepartmentInvasport &&
            currentApplication.status === AppStatus.DepartmentInvasportHeadEmployeeReview &&
            isHeadOrTrustedEmployee && (
              <>
                <button
                  className="icon-text-btn-primary btn-primary"
                  onClick={organisationHeadApprove}
                >
                  Узгодити
                </button>
                <button
                  className="icon-text-btn-secondary"
                  onClick={organisationHeadCommissionClick}
                >
                  На&nbsp;комісію
                </button>
                <button className="icon-text-btn-secondary" onClick={organisationHeadDeclineClick}>
                  <i className="icon dismiss-icon"></i>Відхилити&nbsp;заявку
                </button>
              </>
            )}

          {currentApplication.status === AppStatus.DepartmentHeadEmployeeReview &&
            (currentOrganisation.type === OrgType.DepartmentOlympic ||
              currentOrganisation.type === OrgType.DepartmentNonOlympic) &&
            isHeadOrTrustedEmployee && (
              <>
                {(currentApplication.commissionConclusion=='1'|| currentApplication.commissionConclusion==null) && <button
                  className="icon-text-btn-primary btn-primary"
                  onClick={organisationHeadApprove}
                >
                  Узгодити
                </button>}
               {(currentApplication.commissionConclusion=='0'|| currentApplication.commissionConclusion==null) &&  <button className="icon-text-btn-secondary" onClick={organisationHeadDeclineClick}>
                  <i className="icon dismiss-icon"></i>Відхилити&nbsp;заявку
                </button>}
                {(currentApplication.commissionConclusion==null) && <button
                  className="icon-text-btn-secondary"
                  onClick={organisationHeadCommissionClick}
                >
                  На&nbsp;комісію
                </button>}
              </>
            )}

          {(currentApplication.status === AppStatus.DepartmentInvasportReview) &&
            currentOrganisation.type === OrgType.DepartmentInvasport &&
            !isHeadOrTrustedEmployee && (
              <>
                <button
                  className="icon-text-btn-primary btn-primary"
                  onClick={invasportAcceptClick}
                >
                  Узгодити
                </button>
                <button className="icon-text-btn-secondary" onClick={invasportCommissionClick}>
                  На&nbsp;комісію
                </button>
                <button className="icon-text-btn-secondary" onClick={invasportDeclineClick}>
                  <i className="icon dismiss-icon"></i>Відхилити&nbsp;заявку
                </button>
              </>
            )}

            {!currentApplication.isDecline && currentApplication.status=== AppStatus.CertificateGiven && !currentApplication.hasCopy &&  (isAdmin || currentOrganisation.type === OrgType.RewardSport) &&
            <>
                {!currentApplication.hasCopy && <button
                  className="icon-text-btn-primary btn-primary"
                  onClick={printDublicateCert}
                  >
                  Дублікат посвідчення
                </button>}
                <button className="icon-text-btn-secondary" onClick={declineGettedCertificateClick}>
                  <i className="icon dismiss-icon"></i>Скасувати звання
                </button>
            </>
            }

          {(currentApplication.status === AppStatus.RewardSportReview ||
            currentApplication.status === AppStatus.RewardSportCommission) &&
            currentOrganisation.type === OrgType.RewardSport && (
              <>
                {currentApplication.status === AppStatus.RewardSportCommission ?
                  <button
                    className="icon-text-btn-primary btn-primary"
                    onClick={awardCommissionClick}
                  >
                    Узгодити&nbsp;заявку
                  </button>
                 : (
                  <button className="icon-text-btn-primary btn-primary" onClick={awardAccept}>
                    Узгодити&nbsp;заявку
                  </button>
                )}
                {currentApplication.status === AppStatus.RewardSportCommission ? (
                  <button className="icon-text-btn-secondary" onClick={awardDeclineClick}>
                    <i className="icon dismiss-icon"></i>Відхилити&nbsp;заявку
                  </button>
                ) : (
                  ''
                )}
              </>
            )}

          {(currentApplication.status === AppStatus.CategoryAssigned ||
            currentApplication.status === AppStatus.CertificateReady) &&
            currentOrganisation.type === OrgType.RewardSport && (
              <>
                <select autoFocus={true} onChange={onSelectStatus}>
                  <option disabled></option>
                  {applicationStatuses.map((appStatus) => {
                    if (appStatus.status > 19) {
                      return (
                        <option
                          value={appStatus.status}
                          id={appStatus.id}
                          disabled={appStatus.status < currentApplication.status}
                          selected={appStatus.status === currentApplication.status}
                        >
                          {appStatus.title}
                        </option>
                      );
                    }
                  })}
                </select>
              </>
            )}
        </div>

        <div className="auth-tab">
          <button
            className={activeTab === 'application' ? 'auth-tab-item selected' : 'auth-tab-item'}
            style={{ color: `${(error.doc && !doc[5]) || error.input ? 'red' : ''}` }}
            onClick={() => setActiveTab('application')}
          >
            Подання
          </button>
          <button
            className={activeTab === 'documents' ? 'auth-tab-item selected' : 'auth-tab-item'}
            style={{
              color: `${
                (error.doc && (!doc[1] || !doc[11] || !doc[3])) || error.doc2 ? 'red' : ''
              }`,
            }}
            onClick={() => setActiveTab('documents')}
          >
            Документи
          </button>
          <button
            className={activeTab === 'comments' ? 'auth-tab-item selected' : 'auth-tab-item'}
            onClick={() => setActiveTab('comments')}
          >
            Коментарі
          </button>
          {/* {((currentApplication.status === AppStatus.SubmissionSubjectFixing &&
            currentApplication.officeCode) ||
            (currentApplication.officeCode && coverList && coverList.length > 0)) && (
            <button
              className={activeTab === 'letters' ? 'auth-tab-item selected' : 'auth-tab-item'}
              onClick={() => setActiveTab('letters')}
            >
              Супровідні листи
            </button>
          )} */}
          <button
            className={activeTab === 'history' ? 'auth-tab-item selected' : 'auth-tab-item'}
            onClick={() => setActiveTab('history')}
          >
            Історія змін
          </button>

          <button
            className={activeTab === 'assign' ? 'auth-tab-item selected' : 'auth-tab-item'}
            onClick={() => setActiveTab('assign')}
          >
            Підписи
          </button>
        </div>

        {activeTab === 'application' && (
          <Application
            validateApplication={validateApplication}
            error={error}
            form={form}
            application={currentApplication}
            isSubmissionSubject={currentOrganisation.type === OrgType.SubmissionSubject}
            doc={doc}
            setForm={setForm}
            fetchDocuments={fetchDocuments}
            fetchApplication={fetchApplication}
          />
        )}
        {activeTab === 'letters' && <ApplyList />}
        {activeTab === 'comments' && <Comments />}
        {activeTab === 'assign' && <Assign applicationStatuses={applicationStatuses} />}
        {activeTab === 'documents' && (
          <Documents
            error={error}
            permission={currentApplication.status}
            org={currentOrganisation.type}
            doc={doc}
            form={form}
            setDoc={setDoc}
            fetchDocuments={fetchDocuments}
            ready={ready}
          />
        )}
        {activeTab === 'history' && <HistoryOfChange />}

        {formActive && invoDepFederation && (
          <Form
            form={comment}
            saveAddChange={disabilityFederationApprove}
            setForm={setComment}
            invoDepFederation={invoDepFederation}
            setFormActive={setFormActive}
          />
        )}

        {formActive && declineReg && (
          <Form
            form={comment}
            saveAddChange={regionSportDeclinApprove}
            setForm={setComment}
            decline={declineReg}
            setFormActive={setFormActive}
          />
        )}
                {formActive && decline && (
          <Form
            form={comment}
            saveAddChange={applicationFixingReg}
            setForm={setComment}
            decline={decline}
            setFormActive={setFormActive}
          />
        )}
        {formActive && office && (
          <Form
            form={comment}
            saveAddChange={officeAcceptDigital}
            setForm={setComment}
            office={office}
            setFormActive={setFormActive}
          />
        )}
        {formActive && department && (
          <Form
            form={comment}
            saveAddChange={assignDepartment}
            setForm={setComment}
            department={department}
            setFormActive={setFormActive}
          />
        )}
        {formActive && trainerApply && (
          <Form
            form={comment}
            saveAddChange={trainerAccept}
            setForm={setComment}
            trainerApply={trainerApply}
            setFormActive={setFormActive}
          />
        )}
          {formActive && trainerHeadApply && (
          <Form
            form={comment}
            saveAddChange={headTrainerClick}
            setForm={setComment}
            trainerHeadApply={trainerHeadApply}
            setFormActive={setFormActive}
          />
        )}
        {formActive && depDecline && (
          <Form
            form={comment}
            saveAddChange={departmentDecline}
            setForm={setComment}
            depDecline={depDecline}
            setFormActive={setFormActive}
          />
        )}
        {formActive && depCommission && (
          <Form
            form={comment}
            saveAddChange={departmentCommission}
            setForm={setComment}
            depCommision={depCommission}
            setFormActive={setFormActive}
          />
        )}
        {formActive && departmentHeadRet && (
          <Form
            form={comment}
            saveAddChange={departmentHeadReturn}
            setForm={setComment}
            departmentHeadRet={departmentHeadRet}
            setFormActive={setFormActive}
          />
        )}
        {formActive && departmentHeadDec && (
          <Form
            form={comment}
            saveAddChange={departmentHeadDecline}
            setForm={setComment}
            departmentHeadDec={departmentHeadDec}
            setFormActive={setFormActive}
          />
        )}
        {formActive && departmentHeadCom && (
          <Form
            form={comment}
            saveAddChange={departmentHeadCommission}
            setForm={setComment}
            departmentHeadCom={departmentHeadCom}
            setFormActive={setFormActive}
          />
        )}
        {formActive && invoDecline && (
          <Form
            form={comment}
            saveAddChange={invoSportDecline}
            setForm={setComment}
            invoDecline={invoDecline}
            setFormActive={setFormActive}
          />
        )}
        {formActive && invoSelect && (
          <Form
            form={comment}
            saveAddChange={invasportSelectDepartment}
            setForm={setComment}
            invoSelect={invoSelect}
            setFormActive={setFormActive}
          />
        )}
        {formActive && invoRetFed && (
          <Form
            form={comment}
            saveAddChange={invoSportRetFeder}
            setForm={setComment}
            invoRetFed={invoRetFed}
            setFormActive={setFormActive}
          />
        )}
        {formActive && incoCom && (
          <Form
            form={comment}
            saveAddChange={invasportCommission}
            setForm={setComment}
            incoCom={incoCom}
            setFormActive={setFormActive}
          />
        )}
        {formActive && invoHeadRet && (
          <Form
            form={comment}
            saveAddChange={invoSportHeadRet}
            setForm={setComment}
            invoHeadRet={invoHeadRet}
            setFormActive={setFormActive}
          />
        )}
        {formActive && organisationHeadRet && (
          <Form
            form={comment}
            saveAddChange={organisationHeadReturn}
            setForm={setComment}
            organisationHeadRet={organisationHeadRet}
            setFormActive={setFormActive}
          />
        )}
        {formActive && organisationHeadDec && (
          <Form
            form={comment}
            saveAddChange={organisationHeadDecline}
            setForm={setComment}
            departmentHeadDec={organisationHeadDec}
            setFormActive={setFormActive}
          />
        )}
        {formActive && organisationHeadCom && (
          <Form
            form={comment}
            saveAddChange={organisationHeadCommission}
            setForm={setComment}
            organisationHeadCom={organisationHeadCom}
            setFormActive={setFormActive}
          />
        )}

        {formActive && awardCom && (
          <Form
            form={comment}
            saveAddChange={awardCommission}
            setForm={setComment}
            awardCom={awardCom}
            setFormActive={setFormActive}
          />
        )}
        {formActive && awardRet && (
          <Form
            form={comment}
            saveAddChange={awardReturn}
            setForm={setComment}
            awardRet={awardRet}
            setFormActive={setFormActive}
          />
        )}
        {formActive && awardDec && (
          <Form
            form={comment}
            saveAddChange={awardDecline}
            setForm={setComment}
            awardDec={awardDec}
            setFormActive={setFormActive}
          />
        )}

        {formActive && invoFederation && (
          <Form
            form={comment}
            saveAddChange={invoFeder}
            setForm={setComment}
            invoFederation={invoFederation}
            setFormActive={setFormActive}
          />
        )}

        {formActive && printCer && (
          <Form
            form={orderForm}
            saveAddChange={setPrintSave}
            printCer={printCer}
            setForm={setOrderForm}
            selectDep={selectDep}
            setFormActive={setFormActive}
            selectId={applicationId}
          />
        )}

        {formActive && declineCer && (
          <Form
            form={orderForm}
            saveAddChange={declineGettedCertificate}
            declineCer={declineCer}
            setForm={setOrderForm}
            setFormActive={setFormActive}
          />
        )}

        {alertActive && (
          <div className="alert-label">
            <div>Ви впевнені, що хочете видалити?</div>
            <div className="alert-label-buttons">
              <button className="icon-text-btn-secondary garbage" onClick={deleteApplication}>
                Видалити
              </button>
              <button
                className="round-btn-secondary close"
                onClick={() => setAlertActive(false)}
              ></button>
            </div>
          </div>
        )}
        {signatureError && (
          <div className="alert-label">
            <div>{signatureError}</div>
            <div className="alert-label-buttons">
              <button
                className="round-btn-secondary close"
                onClick={() => setSignatureError(null)}
              ></button>
            </div>
          </div>
        )}
        {enabledKep && (
          <DigitalSignatureModal
            {...kepModalProps}
            onClose={() => setKepModalProps({ visible: false })}
          />
        )}
      </div>
    </>
  );
};

export default CreateApplicationPage;
